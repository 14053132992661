import React, { useEffect, useState } from 'react'
import { mobilVariant, addressVariant, yandanBakiyeVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import { uyebilgileri, vegaCariSorgula2 } from '../../api/api'
import axios from 'axios'
import BottomBar from '../../components/bottombar'
import { Input, Modal } from 'reactstrap'
import print from '../../img/print.png'
import MenuHeader from '../../components/menuHeader'
import { paraFormat } from '../../components/general'
import { XCircle } from 'react-feather'

const Extracts = ({ basketLengthh }) => {
    const [data, setData] = useState([])
    const [initDate, setInitDate] = useState('2000-01-01')
    const [endDate, setEndDate] = useState("")
    const [bakiye, setBakiye] = useState('0')
    const [selectExtract, setSelectExtract] = useState('')
    const [profile, setProfile] = useState([])
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    var now = Date.now();
    var utc = new Intl.DateTimeFormat('tr-TR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(now).replace(".", "-").replace(".", "-").split("-")
    var days = `${utc[2]}-${utc[1]}-${utc[0]}`

    const getDate = (value, endDate2) => {
        axios.get(`${vegaCariSorgula2}${value}/${initDate}/${endDate === '' ? endDate2 : endDate}`).then(res => {
            var bakiye2 = 0;
            for (var i = 0; i < res.data.length; i++) {
                bakiye2 += parseFloat(res.data[i].KALANBAKIYE)
            }
            setBakiye(bakiye2)
            setData(res.data)
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const getData = () => {
        axios.get(`${uyebilgileri}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.data !== undefined && res.data !== null) {
                setProfile(res.data)
                getDate(res.data[0].Musteri_BlKodu, days)
            }
        })
    }

    const printPage = () => {
        var div = document.getElementById("printerDiv");
        div.innerHTML = `<iframe src='https://www.tekinteknoloji.com.tr/Bayi/EkstrePopup.aspx?evrakNo=${selectExtract}' onload="this.contentWindow.print();"></iframe>`
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='my-payments'>
            <div className='noPrint'>
                <MenuHeader title={'HESAP EKSTRE'} basket={basketLengthh} isSearch={false} />
            </div>
            <div id="printerDiv" className="d-none"></div>
            <motion.div {...yandanBakiyeVariant}>
                <div className='bakiye p-2'>
                    <span>{bakiye > 0 ? 'Borç Bakiyeniz' : 'Alacak Bakiyeniz'}: {paraFormat(bakiye)} $</span>
                </div>
            </motion.div>

            <div className='date-print'>
                <div className='noPrint'>
                    <Input type='date' onChange={e => { setInitDate(e.target.value); getDate(profile[0].Musteri_BlKodu, days); }} defaultValue={"2000-01-01"} />
                </div>
                <div className='noPrint'>
                    <Input type='date' onChange={e => { setEndDate(e.target.value); getDate(profile[0].Musteri_BlKodu, e.target.value); }} defaultValue={days} />
                </div>
                <div onClick={() => window.print()} className='noPrint'>
                    <img src={print} alt='' />
                </div>
            </div>

            <div id='tableview'>
                {data !== undefined && data !== null ? data.length !== 0 ? <div className='tables'>
                    <div className='w-100'><b>EVRAK NO</b></div>
                    <div className='w-100'><b>TARİH</b></div>
                    <div className='w-100'><b>ALACAK</b></div>
                    <div className='w-100'><b>BORÇ</b></div>
                </div> : null : null}

                <motion.div {...addressVariant}>
                    {data !== undefined && data !== null ? data.length !== 0 ? <div className='tabless'>
                        {
                            data.reverse().map((item, index) => {
                                return (
                                    <div className='item' key={index} onClick={() => { setSelectExtract(item.EVRAKNO); printPage(); }}>
                                        <div><small>{item.EVRAKNO}</small></div>
                                        <div style={{ marginLeft: 20 }}><small>{new Date(item.TARIH).toLocaleDateString()}</small></div>
                                        <div style={{ marginLeft: 20 }}><small>{paraFormat(item.ALACAK)}$</small></div>
                                        <div style={{ marginLeft: 20 }}><small>{paraFormat(item.BORC)}$</small></div>
                                    </div>
                                )
                            })
                        }
                    </div> : null : null}
                </motion.div>
            </div>

            <div className='noPrint'>
                <BottomBar basket={basketLengthh} />
            </div>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

        </motion.div>
    )
}

export default Extracts