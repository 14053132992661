import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AnimatedRoutes from './router'

const App = () => {

  useEffect(() => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, [])

  return (
    <>
      <Router>
        <AnimatedRoutes />
      </Router>
    </>
  )
}

export default App