import { mobilVariant, yandanVariant } from '../../../components/animation'
import { useForm } from 'react-hook-form'
import { motion } from 'framer-motion'
import Image from '../../../img/image.jpg'
import logo from '../../../img/logo.png'
import { Input, Form, Button, Modal } from 'reactstrap'
import React, { useState } from 'react'
import axios from 'axios'
import { sifremiunuttum } from '../../../api/api.js'
import { XCircle } from 'react-feather'

const ForgetPassword = () => {
    const {handleSubmit} = useForm()
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successModal, setSuccessModal] = useState(false)
    const [message, setMessage] = useState('')
    const [modal, setModal] = useState(false)

    const forgetPass = (values, e)=> {
        axios.get(`${sifremiunuttum}${window.btoa(e.target[0].value)}`).then(res=>{
            if (res.data[0].durum === '0') {
                setMessage(res.data[0].sonuc)
                setModal(true)
            } else {
                setMessage(res.data[0].sonuc)
                setSuccessModal(true)
                setTimeout(() => {
                    setSuccessModal(false)
                    window.location.href = '/'
                }, 2000);
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    return (
        <motion.div {...mobilVariant} className='forgetPassword'>
            <div className='w-100'>
                <img src={Image} className='w-100' alt='' />
            </div>
            <motion.div {...yandanVariant}>
                <div className='mt-4 text-center'>
                    <img src={logo} height={40} alt='' />
                </div>
                <div className='mt-3 text-center text-secondary'>
                    <span style={{ fontSize: 13 }}>Hesabınızı kurtamak için lütfen mail adresinizi giriniz.</span>
                </div>
                <div className='mt-4' style={{ width: '86%', marginLeft: '7%' }}>
                    <Form onSubmit={handleSubmit(forgetPass)}>
                        <Input type='input' placeholder='E-Mail Adresi' />
                        <Button style={{ borderRadius: 20, fontSize: 14, padding: '5px 50px' }} className="mt-3 w-100">Gönder</Button>
                    </Form>
                </div>
                <div className="d-flex justify-content-center w-100">
                    <Button className='bg-white border-secondary text-secondary mt-3' style={{ borderRadius: 20, fontSize: 14, padding: '5px 50px' }} onClick={() => window.location.href = '/login'}>GİRİŞ YAP</Button>
                    <Button className='bg-white border-secondary text-secondary mt-3 ml-2' style={{ borderRadius: 20, fontSize: 14, padding: '5px 50px' }} onClick={() => window.location.href = '/register'}>ÜYE OLUN</Button>
                </div>
                <div className='text-secondary mt-4 d-flex justify-content-center' style={{ fontSize: 13 }}>
                    <div onClick={() => window.location.href = '/contracts/55'}><span>Üyelik Sözleşmesi</span></div>
                    <div className='ml-3 mr-3'><span>|</span></div>
                    <div onClick={() => window.location.href = '/contracts/8'}><span>Gizlilik Taahhütü</span></div>
                </div>
            </motion.div>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div className='text-center mt-3'><h6>TECH-IN</h6></div>
                <div className='ml-2 mr-2 text-center'><small>{message}</small></div>
                <div onClick={() => setModal(false)} className='border border-secondary rounded p-1 mt-2 text-center align-items-center text-secondary'>Tamam</div>
            </Modal>

            <Modal isOpen={successModal} toggle={() => setSuccessModal(!successModal)} className='modal-dialog-centered'>
                <div className="p-2">
                    <div className='text-center mt-3'><h6>TECH-IN</h6></div>
                    <div className='ml-2 mr-2 text-center'><small>{message}</small></div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>
        </motion.div>
    )
}

export default ForgetPassword