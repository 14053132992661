import React, { useEffect, useState } from 'react'
import { Button, Input, Modal } from 'reactstrap'
import Image from '../../../img/image.jpg'
import logo from '../../../img/logo.png'
import { mobilVariant, yandanVariant } from '../../../components/animation'
import { motion } from 'framer-motion'
import axios from 'axios'
import { districtList, kayitol } from '../../../api/api'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { cities } from '../../../components/options'
import logoImg from '../../../img/logo.png'
import { XCircle } from 'react-feather'

const animatedComponents = makeAnimated();

const Register = () => {
    const [data, setData] = useState({ ad: '', soyad: '', telefon: '', email: '', sifre: '', firmaUnvan: '', vergiDairesi: '', vergiNumarasi: '', il: '', ilce: '', adres: '' })
    const [modal, setModal] = useState(false)
    const [currentStatus2, setCurrentStatus2] = useState({ value: 0, label: 'Şehir Seçiniz' })
    const [selectDistrict, setSelectDistrict] = useState({ value: 0, label: 'İlçe Seçiniz' })
    const [city, setCity] = useState('')
    const [successModal, setSuccessModal] = useState(false)
    const [districtOpt, setDistrictOpt] = useState([])
    const [showDist, setShowDist] = useState(false)
    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)
    const [sozlesme, setSozlesme] = useState(false)
    const [loadLogo, setLoadLogo] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getDistrict = (id) => {
        axios.get(`${districtList}${window.btoa(id)}`).then(res => {
            console.log(res.data)
            const newList = []
            if (res.data !== null) {
                for (let i = 0; i < res.data.length; i++) {
                    newList.push({ label: res.data[i].Usis_Ad, value: res.data[i].Usis_Id })
                }
                setDistrictOpt(newList)
                setShowDist(true)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const registerSave = () => {
        if (sozlesme) {
            axios.get(`${kayitol}${data.ad},${data.soyad},${data.telefon},${data.email},${data.sifre},${data.firmaUnvan},${data.vergiDairesi},${data.vergiNumarasi},${window.btoa(currentStatus2.label)},${window.btoa(selectDistrict.label)},${data.adres}`).then(res => {
                if (res.data[0].durum === '0') {
                    setMessage(res.data[0].sonuc)
                    setModal(true)
                } else {
                    setSuccessModal(true)
                    setTimeout(() => {
                        setSuccessModal(false)
                        window.location.href = '/'
                    }, 2000);
                }
            }).catch(er => {
                setErrorModal(!errorModal)
                setErrorMessage(er.message)
            })
        } else {
            setMessage('Lütfen üyelik sözleşmesini kabul ediniz!')
            setModal(true)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setLoadLogo(true)
        }, 1000)
    }, [])

    return (
        loadLogo ? <motion.div {...mobilVariant} className='register pb-5'>
            <div className='w-100'>
                <img src={Image} className='w-100' alt='' />
            </div>
            <motion.div {...yandanVariant}>
                <div className='mt-4 text-center'>
                    <img src={logo} height={40} alt='' />
                </div>
                <div className='mt-3 text-center text-secondary'>
                    <span style={{ fontSize: 13 }}>TECH-IN Bayi Kayıt</span>
                </div>
                <div className='mt-4' style={{ width: '86%', marginLeft: '7%' }}>
                    <Input type='input' placeholder='Ad' onBlur={e => setData(prev => ({ ...prev, ad: window.btoa(unescape(encodeURIComponent(e.target.value))) }))} />
                    <Input type='input' placeholder='Soyad' className='mt-2' onBlur={e => setData(prev => ({ ...prev, soyad: window.btoa(unescape(encodeURIComponent(e.target.value))) }))} />
                    <Input type='tel' placeholder='Telefon' className='mt-2' onBlur={e => setData(prev => ({ ...prev, telefon: window.btoa(unescape(encodeURIComponent(e.target.value))) }))} />
                    <Input type='input' placeholder='Email Adresi' className='mt-2' onBlur={e => setData(prev => ({ ...prev, email: window.btoa(unescape(encodeURIComponent(e.target.value))) }))} />
                    <Input type='password' placeholder='Şifre' className='mt-2' onBlur={e => setData(prev => ({ ...prev, sifre: window.btoa(unescape(encodeURIComponent(e.target.value))) }))} />
                    <Input type='input' placeholder='Firma Ünvanı' className='mt-2' onBlur={e => setData(prev => ({ ...prev, firmaUnvan: window.btoa(unescape(encodeURIComponent(e.target.value))) }))} />
                    <Input type='input' placeholder='Vergi Dairesi' className='mt-2' onBlur={e => setData(prev => ({ ...prev, vergiDairesi: window.btoa(unescape(encodeURIComponent(e.target.value))) }))} />
                    <Input type='input' placeholder='Vergi Numarası' className='mt-2' onBlur={e => setData(prev => ({ ...prev, vergiNumarasi: window.btoa(unescape(encodeURIComponent(e.target.value))) }))} />
                    <Select
                        noOptionsMessage={() => 'Şehir Bulunamadı!'}
                        isClearable={false}
                        className="react-select mt-2 mb-2"
                        classNamePrefix="select"
                        options={cities}
                        styles={{ control: (baseStyles, state) => ({ ...baseStyles, fontSize: 13, }), }}
                        components={animatedComponents}
                        value={currentStatus2}
                        onChange={data => {
                            setCurrentStatus2(data);
                            setCity(window.btoa(unescape(encodeURIComponent(data.label))))
                            getDistrict(data.value)
                        }}
                    />

                    <Select
                        noOptionsMessage={() => 'Şehir seçiniz!'}
                        isClearable={false}
                        className="react-select mb-2"
                        classNamePrefix="select"
                        options={districtOpt}
                        isDisabled={!showDist}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        components={animatedComponents}
                        value={selectDistrict}
                        onChange={data => {
                            setSelectDistrict(data);
                        }}
                    />
                    <Input type='input' placeholder='Adres Girin' className='mt-2' onBlur={e => setData(prev => ({ ...prev, adres: window.btoa(unescape(encodeURIComponent(e.target.value))) }))} />
                    <label className='mt-3 mb-3'>
                        <input type='checkbox' name='uyelik' onChange={e => setSozlesme(e.target.checked)} />
                        <span htmlFor='uyelik' style={{ marginLeft: 10 }}><b>Üyelik Sözleşmesi </b>'ni okudum ve kabul ediyorum.</span>
                    </label>
                </div>

                <div className='text-center align-items-center mt-4'>
                    <Button style={{ borderRadius: 20, fontSize: 14, padding: '5px 50px' }} onClick={() => registerSave()}>ÜYE OLUN</Button>
                    <div className='text-secondary mt-2 mb-2'><small>ya da</small></div>
                    <Button className='bg-white border-secondary text-secondary' style={{ borderRadius: 20, fontSize: 14, padding: '5px 50px' }} onClick={() => window.location.href = '/login'}>GİRİŞ YAPIN</Button>
                </div>

                <div className='text-secondary mt-4 d-flex justify-content-center' style={{ fontSize: 13 }}>
                    <div onClick={() => window.location.href = '/contracts/55'}><span>Üyelik Sözleşmesi</span></div>
                    <div className='ml-3 mr-3'><span>|</span></div>
                    <div onClick={() => window.location.href = '/contracts/8'}><span>Gizlilik Taahhütü</span></div>
                </div>
            </motion.div>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div className='text-center mt-3'><h6>TECH-IN</h6></div>
                <div className='ml-2 mr-2 text-center'><small>{message}</small></div>
                <div onClick={() => setModal(false)} className='border border-secondary rounded p-1 mt-2 text-center align-items-center text-secondary'>Tamam</div>
            </Modal>

            <Modal isOpen={success} toggle={() => setSuccess(!success)} className='modal-dialog-centered'>
                <div className='text-center mt-3'><h6>TECH-IN</h6></div>
                <div className='ml-2 mr-2 text-center'><small>{message}</small></div>
                <div onClick={() => { setModal(false); window.location.href = '/login' }} className='border border-secondary rounded p-1 mt-2 text-center align-items-center text-secondary'>Tamam</div>
            </Modal>

            <Modal isOpen={successModal} toggle={() => setSuccessModal(!successModal)} className='modal-dialog-centered'>
                <div className='text-center mt-3'><h6>TECH-IN</h6></div>
                <div className='ml-2 mr-2 text-center'><small>Kayıt işleminiz başarılı. Yönetici hesabınızı aktif ettikten sonra giriş yapabilirsiniz.</small></div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>
        </motion.div> : <div className='logoImg'>
            <img src={logoImg} alt='' height={30} />
        </div>
    )
}

export default Register