import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import '../../css/style.css'
import { addressInfoVariant, addressSaoVariant, mobilVariant } from '../../components/animation'
import { musteriAdreslerineGoreKargoFiyati, musteriadresler } from '../../api/api'
import axios from 'axios'
import BottomBar from '../../components/bottombar'
import { PlusCircle, XCircle } from 'react-feather'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { Button, Input, Modal } from 'reactstrap'
import MenuHeader from '../../components/menuHeader'

const animatedComponents = makeAnimated();

const AddressInfo = ({ basketLength }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [currentStatus1, setCurrentStatus1] = useState({})
    const [currentStatus2, setCurrentStatus2] = useState({})
    const [currentStatus3, setCurrentStatus3] = useState({})
    const [cargoOpt, setCargoOpt] = useState({})
    const [tesAdresId, setTesAdresId] = useState(0)
    const [fatAdresId, setFatAdresId] = useState(0)
    const [optAdres, setOptAdres] = useState({ number: 0, value: 0, label: 'Lütfen Adres Seçiniz' })
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getCargo = () => {
        axios.get(`${musteriAdreslerineGoreKargoFiyati}${window.btoa(tesAdresId)}`).then(res => {
            if (res.status === 200) {
                console.log(res.data)
                const newList = []
                for (let i = 0; i < res.data.length; i++) {
                    newList.push({ value: res.data[i].Kargo_Id, number: res.data[i].Kargo_Id, label: `${res.data[i].Kargo_Baslik} | ${res.data[i].Kargo_Fiyat}₺` })
                }
                setCargoOpt(newList)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const getData = () => {
        axios.get(`${musteriadresler}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const newList = []
                for (let i = 0; i < res.data.length; i++) {
                    newList.push({
                        number: res.data[i].MusteriAdres_Id,
                        value: res.data[i].MusteriAdres_Id,
                        label: res.data[i].MusteriAdres_Baslik
                    })
                }
                setOptAdres(newList)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='address-info'>
            <MenuHeader title={'ADRES BİLGİLERİ'} basket={basketLength} isSearch={false} />
            <motion.div {...addressSaoVariant} className='sao'>
                <div className='section' onClick={() => window.location.href = '/basket'}><span>Sepetim</span></div>
                <div className='line'></div>
                <div className='section select'><span>Adres Bilgileri</span></div>
                <div className='line'></div>
                <div className='section'><span>Ödeme Bilgileri</span></div>
            </motion.div>

            <motion.div {...addressInfoVariant}>
                <div className='tes-adres'>
                    <div><span>Teslimat Adresi Seçiniz</span></div>
                    <div className='newAddress' onClick={() => window.location.href = '/address-edit/0'}><PlusCircle style={{ marginRight: 3, marginTop: -2 }} size={18} color='white' /><small>Yeni Teslimat Adresi</small></div>
                </div>

                <div className='mt-1'>
                    <Select
                        noOptionsMessage={() => 'Adres Bulunamadı!'}
                        isClearable={false}
                        className="react-select"
                        classNamePrefix="select"
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        options={optAdres}
                        components={animatedComponents}
                        value={currentStatus1}
                        onChange={data => {
                            setCurrentStatus1(data);
                            setTesAdresId(data.value)
                            localStorage.setItem('tesAdres', JSON.stringify(data))
                            getCargo()
                        }}
                    />
                </div>

                <div className='tes-adres mt-4'>
                    <div><span>Fatura Adresi Seçiniz</span></div>
                    <div className='newAddress' onClick={() => window.location.href = '/address-edit/0'}><PlusCircle style={{ marginRight: 3, marginTop: -2 }} size={18} color='white' /><small>Yeni Fatura Adresi</small></div>
                </div>

                <div className='mt-1'>
                    <Select
                        noOptionsMessage={() => 'Adres Bulunamadı!'}
                        isClearable={false}
                        className="react-select"
                        classNamePrefix="select"
                        options={optAdres}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        components={animatedComponents}
                        value={currentStatus2}
                        onChange={data => {
                            setCurrentStatus2(data);
                            setFatAdresId(data.value)
                            localStorage.setItem('fatAdres', JSON.stringify(data))
                            getCargo()
                        }}
                    />
                </div>

                <div className='kargo mt-4'>
                    <div><span>Kargo Seçiniz</span></div>
                </div>

                <div className='mt-1'>
                    <Select
                        noOptionsMessage={() => 'Kargo bulunamadı!'}
                        isClearable={false}
                        className="react-select"
                        classNamePrefix="select"
                        options={cargoOpt}
                        isDisabled={fatAdresId && tesAdresId ? false : true}
                        components={animatedComponents}
                        value={currentStatus3}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        onChange={data => {
                            setCurrentStatus3(data);
                            localStorage.setItem('cargo', JSON.stringify(data))
                        }}
                    />
                </div>

                <div className='description'>
                    <div><span style={{ fontSize: 12 }}>Müşteri Notu</span></div>
                    <div className='mt-2'>
                        <Input type='textarea' disabled={fatAdresId && tesAdresId ? false : true} onChange={e => localStorage.setItem('musteriNotu', e.target.value)} />
                    </div>
                </div>

                <div className='tamamla'>
                    <Button onClick={() => currentStatus3.value !== undefined ? window.location.href = '/pay-info' : setModalOpen(!modalOpen)} disabled={fatAdresId && tesAdresId ? false : true}>SONRAKİ ADIMA GEÇ</Button>
                </div>
            </motion.div>

            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} className='modal-dialog-centered'>
                <div className='p-2'>
                    <div className='text-danger'><b>Alanlar Boş Bırakılamaz!</b></div>
                    <div className='mt-2'><span>Lütfen gerekli alanların seçimini yapınız!</span></div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default AddressInfo