import React, { useEffect, useState } from 'react'
import { yandanAboutVariant, mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import BottomBar from '../../components/bottombar'
import { sayfalar } from '../../api/api'
import axios from 'axios'
import MenuHeader from '../../components/menuHeader'
import { Modal } from 'reactstrap'
import { XCircle } from 'react-feather'

const About = ({ basketLengthh }) => {
    const [data, setData] = useState([])
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getData = () => {
        axios.get(`${sayfalar}`).then(res => {
            if (res.status === 200) {
                if (res.data !== undefined && res.data !== null) {
                    setData(res.data[0])
                }
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='about'>
            <MenuHeader title={'ASİL HAKKINDA'} isSearch={false} basket={basketLengthh} />

            <motion.div {...yandanAboutVariant}>
                <div className='content' dangerouslySetInnerHTML={{ __html: data !== undefined && data !== null ? data.Sayfa_Aciklama : null }}></div>
            </motion.div>

            <BottomBar basket={basketLengthh} />
            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>
        </motion.div>
    )
}

export default About