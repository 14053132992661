import React, { useState } from 'react'
import Image from '../../../img/image.jpg'
import logo from '../../../img/logo.png'
import { Button, Input, Modal } from 'reactstrap'
import { motion } from 'framer-motion'
import { mobilVariant, yandanVariant } from '../../../components/animation'
import axios from 'axios'
import { giris } from '../../../api/api'
import { XCircle } from 'react-feather'
import { toUtf8 } from '../../../components/general'

const Login = () => {
    const [name, setName] = useState('')
    const [pass, setPass] = useState('')
    const [modal, setModal] = useState(false)
    const [message, setMessage] = useState('')
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const login = () => {
        if (name !== '' && pass !== '') {
            axios.get(`${giris}${name},${pass}`).then(res => {
                console.log(res)
                if (res.data[0].durum !== '0') {
                    localStorage.setItem('auth', res.data[0].durum)
                    localStorage.setItem('info', JSON.stringify(res.data[0]))
                    window.location.href = '/'
                } else {
                    localStorage.clear()
                    setMessage(res.data[0].sonuc)
                    setModal(true)
                }
            }).catch(er => {
                setErrorModal(!errorModal)
                setErrorMessage(er.message)
            })
        }
    }

    return (
        <motion.div {...mobilVariant} className='login'>
            <div className='w-100'>
                <img src={Image} className='w-100' alt='' />
            </div>
            <motion.div {...yandanVariant}>
                <div className='mt-4 text-center'>
                    <img src={logo} height={40} alt='' />
                </div>
                <div className='mt-3 text-center text-secondary'>
                    <span style={{ fontSize: 13 }}>TECH-IN üyesiyseniz lütfen giriş yapın</span>
                </div>
                <div className='mt-4' style={{ width: '86%', marginLeft: '7%' }}>
                    <Input type='input' placeholder='E-Mail Adresi' onChange={e => setName(window.btoa(e.target.value))} />
                    <Input type='password' placeholder='Şifre' className='mt-2' onChange={e => setPass(window.btoa(toUtf8(e.target.value)))} />
                </div>
                <div className='text-right text-secondary mr-4 mt-1'>
                    <span style={{ fontSize: 13 }} onClick={() => window.location.href = '/forgetPassword'}>Şifremi Unuttum</span>
                </div>
                <div className='text-center align-items-center mt-4'>
                    <Button className='' style={{ borderRadius: 20, fontSize: 14, padding: '5px 50px' }} onClick={() => login()}>GİRİŞ YAPIN</Button>
                    <div className='text-secondary mt-2 mb-2'><small>ya da</small></div>
                    <Button className='bg-white border-secondary text-secondary' style={{ borderRadius: 20, fontSize: 14, padding: '5px 50px' }} onClick={() => window.location.href = '/register'}>ÜYE OLUN</Button>
                </div>
                <div className='text-secondary mt-4 d-flex justify-content-center' style={{ fontSize: 13 }}>
                    <div onClick={() => window.location.href = '/contracts/55'}><span>Üyelik Sözleşmesi</span></div>
                    <div className='ml-3 mr-3'><span>|</span></div>
                    <div onClick={() => window.location.href = '/contracts/8'}><span>Gizlilik Taahhütü</span></div>
                </div>
            </motion.div>
            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div className='text-center mt-3'><h6>TECH-IN</h6></div>
                <div className='ml-2 mr-2'><small>{message}</small></div>
                <div onClick={() => setModal(false)} className='border border-secondary rounded p-1 mt-2 text-center align-items-center text-secondary'>Tamam</div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>
        </motion.div>

    )
}

export default Login