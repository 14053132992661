import React, { useEffect, useState } from 'react'
import { mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import axios from 'axios'
import { siparisdetay } from '../../api/api'
import BottomBar from '../../components/bottombar'
import { useParams } from 'react-router-dom'
import logo from '../../img/logo.png'
import print from '../../img/print.png'
import { Card, Modal } from 'reactstrap'
import MenuHeader from '../../components/menuHeader'
import { XCircle } from 'react-feather'

const OrderDetail = ({ basketLengthh }) => {
  const [data, setData] = useState([])
  const [orderDatas, setOrderDatas] = useState([])
  const [errorModal, setErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { id } = useParams()

  const getData = () => {
    axios.get(`${siparisdetay}${window.btoa(id)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
      if (res.status === 200) {
        const newList = []
        setData(res.data)
        for (let i = 0; i < res.data.Table2.length; i++) {
          newList.push(res.data.Table2[i])
        }
        setOrderDatas(newList)
      }
    }).catch(er => {
      setErrorModal(!errorModal)
      setErrorMessage(er.message)
    })
  }

  console.log(orderDatas)

  useEffect(() => { getData() }, [])

  return (
    <motion.div {...mobilVariant} className='order-detail'>
      <div className='noPrint'>
        <MenuHeader basket={basketLengthh} title={'SİPARİŞ DETAY'} isSearch={false} />
      </div>

      <div className='prints'>
        <div className='d-flex justify-content-between p-3'>
          <div></div>
          <div><img src={logo} alt='' style={{ width: '90%' }} /></div>
          <div className='noPrint' onClick={() => { window.print() }}><img src={print} alt='' style={{ height: 20 }} /></div>
        </div>
        <div className='text-center'><small>Sipariş Detaylarınız Aşağıda Listelenmektedir.</small></div>
        <Card className='shadow text-center align-items-center mt-2'>
          <div><small>Sipariş Kodunuz</small></div>
          <div style={{ color: '#242d41' }}><small>{data.Table1 !== undefined ? data.Table1[0].Siparis_Kodu : 'Bulunamadı'}</small></div>
        </Card>
      </div>

      <div className='fiyat-miktar'>
        <Card className='shadow'>
          {
            orderDatas.map((item, index) => {
              return (
                <div className='d-flex justify-content-between pt-1 pb-1 align-items-center mb-3' key={index}>
                  <div className='ml-2 mr-2'>{item.Medya !== undefined ? <img src={item.Medya} alt='' width={60} /> : <img src={logo} alt='' width={60} />}</div>

                  <div>
                    <div><small style={{ display: 'block' }}>{item.SiparisDetay_UrunAd !== undefined ? item.SiparisDetay_UrunAd : 'Ürün Adı Bulunamadı!'}</small></div>
                    <div><small>URUN KODU: {item.SiparisDetay_StokTicariKod !== undefined ? item.SiparisDetay_StokTicariKod : ''}</small></div>
                  </div>

                  <div className='text-center'>
                    <div><b>Fiyat</b></div>
                    <div><small>{item.SiparisDetay_UrunTlTutar !== undefined ? item.SiparisDetay_UrunTlTutar : ''}₺</small></div>
                  </div>

                  <div className='ml-3 mr-3 text-center'>
                    <div><b>Miktar</b></div>
                    <div><small>{item.SiparisDetay_Miktar !== undefined ? item.SiparisDetay_Miktar : ''}</small></div>
                  </div>
                </div>
              )
            })
          }
        </Card>
      </div>

      <div className='siparis-tutar'>
        <Card className='shadow'>
          <div><b>Sipariş Tutarı</b></div>
          <div>
            <div><small>Sipariş Tutarı: {data.Table1 !== undefined ? data.Table1[0].Siparis_SepetToplamTutari : '0 ₺'}</small></div>
            <div style={{ marginTop: -5 }}><small>Sipariş KDV Tutarı: {data.Table1 !== undefined ? data.Table1[0].Siparis_SepetToplamKdvTutari : '0 ₺'}</small></div>
          </div>
        </Card>
      </div>

      <div className='teslimat'>
        <Card>
          <div><b>Teslimat ve Fatura Adresi</b></div>
          <div className='mt-2'><b className='text-dark'>Teslimat Adresiniz</b></div>
          <div style={{ marginTop: -5 }}><small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_TeslimatAdresi : '' }}></small></div>
          <div className='mt-1'><b className='text-dark'>Fatura Adresiniz</b></div>
          <div style={{ marginTop: -5 }}><small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_FaturaAdresi : '' }}></small></div>
          <div className='mt-1'><b className='text-dark'>Kargonuz</b></div>
          <div style={{ marginTop: -5 }}><small><small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_KargoAd : '' }}></small> | <small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_KargoTutari : '' }}></small>,00 ₺ | Takip Kodu: <small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_KargoTakipKod : '' }}></small></small></div>
        </Card>
      </div>

      <div className='odeme-yontem'>
        <Card>
          <div><b>Ödeme Yöntemi</b></div>
          <div><b className='text-dark'>Havale/EFT</b></div>
          <div><small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Odeme_Yontemi : '' }}></small></div>
        </Card>
      </div>

      <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
        <div>
          <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
            <div><h6>TECH-IN</h6></div>
            <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
          </div>
          <div className='p-2'>
            <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
            <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
          </div>
        </div>
      </Modal>

      <div className='noPrint'>
        <BottomBar basket={basketLengthh} />
      </div>
    </motion.div>
  )
}

export default OrderDetail