import React, { useEffect, useState } from 'react'
import { contractVariant, mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import { ChevronLeft, XCircle } from 'react-feather'
import axios from 'axios'
import { sayfalar } from '../../api/api'
import { useParams } from 'react-router-dom'
import logoImg from '../../img/logo.png'
import { Modal } from 'reactstrap'

const Contracts = () => {
    const [data, setData] = useState([])
    const [loadLogo, setLoadLogo] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const { id } = useParams()

    const getData = () => {
        axios.get(sayfalar).then(res => {
            if (res.status === 200) {
                const value = res.data
                for (let i = 0; i < value.length; i++) {
                    if (parseInt(value[i].Sayfa_Id) === parseInt(id)) {
                        setData(value[i])
                    }
                }
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => {
        getData()
        setTimeout(() => {
            setLoadLogo(true)
        }, 1000)
    }, [])

    return (
        loadLogo ? <motion.div {...mobilVariant} className='contracts'>
            <div className='header'>
                <div className='text-secondary ml-2' onClick={() => window.history.back()}>
                    <ChevronLeft size={30} color='grey' />
                </div>
                <div className='text-secondary align-items-center'>
                    <span>{data.Sayfa_Baslik}</span>
                </div>
                <div></div>
            </div>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <motion.div {...contractVariant} className='content' dangerouslySetInnerHTML={{ __html: data.Sayfa_Aciklama }}></motion.div>
        </motion.div> : <div className='logoImg'>
            <img src={logoImg} alt='' height={30} />
        </div>
    )
}

export default Contracts