import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { mobilVariant } from '../../components/animation'
import Header from '../../components/header'
import Categories from '../categories'
import BottomBar from '../../components/bottombar'
import loading from '../../img/loading.svg'
import logoImg from '../../img/logo.png'
import NewBrands from '../newBrands'
import { Modal } from 'reactstrap'
import { XCircle } from 'react-feather'

const HomePage = ({ basketLength }) => {
    const [isCategory, setIsCategory] = useState(true)
    const [categoryLoad, setCategoryLoad] = useState(false)
    const [productLoad, setProductLoad] = useState(false)
    const [loadLogo, setLoadLogo] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        setTimeout(() => {
            setLoadLogo(true)
        }, 1000)
        setTimeout(() => {
            setCategoryLoad(true)
        }, 1000)
    }, [])

    return (
        loadLogo ? <motion.div {...mobilVariant} className='homepage'>
            <Header basket={basketLength} />
            <div className='bg-white d-flex justify-content-around pt-4 pb-2 text-secondary fw-bolder border-bottom border-secondary w-100' style={{ position: 'fixed', top: 62, left: 0, zIndex: 2 }}>
                <div onClick={() => { setIsCategory(true); setCategoryLoad(true); setProductLoad(false) }}>KATEGORİLER</div>
                <div onClick={() => { setIsCategory(false); setCategoryLoad(false); setProductLoad(true) }}>YENİ ÜRÜNLER</div>
            </div>
            <div className={isCategory ? 'd-flex justify-content-start w-100' : 'd-flex justify-content-end w-100'} style={{ position: 'fixed', top: 118, left: 0, zIndex: 2 }}>
                <div className='w-50' style={{ border: '2px solid #29225c' }}></div>
            </div>

            {!productLoad ? categoryLoad ? <div className='mt-1'>
                <Categories />
            </div> : <div className='w-100 align-items-center d-flex justify-content-center' style={{ height: '100vh' }}>
                <div className='d-none'><Categories /></div>
                <img src={loading} alt='' height={40} />
            </div> : null}

            {!categoryLoad ? productLoad ? <div className='mt-1'>
                <NewBrands />
            </div> : <div className='w-100 align-items-center d-flex justify-content-center' style={{ height: '100vh' }}>
                <div className='d-none'><NewBrands /></div>
                <img src={loading} alt='' height={40} />
            </div> : null}
            <BottomBar basket={basketLength} />
        </motion.div> : <div className='logoImg'>
            <img src={logoImg} alt='' height={30} />

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default HomePage