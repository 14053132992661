import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { homeSliderVariant, imgInitVariant, markaMotion, mobilVariant, vitrinCategoriesVariant } from '../../components/animation'
import loadImg from '../../img/loading.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import '../../css/style.css'
import logo from '../../img/brand-logo.png'
import { brandUrl, markalarGetir, slider, vitrin } from '../../api/api'
import axios from 'axios'
import { XCircle } from 'react-feather'
import { Modal } from 'reactstrap'

const Categories = () => {
    const [loading, setLoading] = useState(false)
    const [sliderData, setSliderData] = useState([])
    const [marka, setMarka] = useState([])
    const [vitrinData, setVitrinData] = useState([])
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const counter = useRef(0);

    const imageLoaded = () => {
        if (sliderData !== null && sliderData !== undefined && vitrinData !== null && vitrinData !== undefined) {
            setTimeout(() => {
                setLoading(true)
            }, 1000)
        } else {
            counter.current += 1;
            if (counter.current >= 8) {
                setTimeout(() => {
                    setLoading(true)
                }, 1000)
            }
        }
    }

    useEffect(() => {
        axios.get(`${slider}${localStorage.getItem('auth')}`).then(res => {
            setSliderData(res.data)
            setTimeout(() => {
                setLoading(true)
            }, 1000);
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })

        axios.get(markalarGetir).then(res => {
            setMarka(res.data)
        })

        axios.get(vitrin).then(res => {
            setVitrinData(res.data)
            setTimeout(() => {
                setLoading(true)
            }, 1000);
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }, [])

    return (
        loading ? <motion.div {...mobilVariant} className='categories'>
            <motion.div {...homeSliderVariant}>
                <Swiper
                    className='w-100'
                    modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                    navigation
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                >
                    {
                        sliderData !== null && sliderData !== undefined ? sliderData.map((item, index) => {
                            if (item.imageUrl.search('#urun') !== -1) {
                                let value = item.imageUrl
                                let values = value.split('#urun')

                                return (
                                    <SwiperSlide key={index}>
                                        <a href={`/product-detail/${values[1]}`}>
                                            <img className='d-none' src={item.imageLink} onLoad={imageLoaded} alt='' />
                                            <motion.div {...imgInitVariant}><img src={item.imageLink} width='100%' /></motion.div>
                                        </a>
                                    </SwiperSlide>
                                )
                            } else if (item.imageUrl.search('arama') !== -1) {
                                let value = item.imageUrl
                                let values = value.split('https://www.tekinteknoloji.com.tr/arama?s=')

                                return (
                                    <SwiperSlide key={index}>
                                        <a href={`/search/1/${values[1]}`}>
                                            <img className='d-none' src={item.imageLink} onLoad={imageLoaded} alt='' />
                                            <motion.div {...imgInitVariant}><img src={item.imageLink} width='100%' /></motion.div>
                                        </a>
                                    </SwiperSlide>
                                )
                            } else {
                                return (
                                    <SwiperSlide key={index}>
                                        <a href={item.imageUrl}>
                                            <img className='d-none' src={item.imageLink} onLoad={imageLoaded} alt='' />
                                            <motion.div {...imgInitVariant}><img src={item.imageLink} width='100%' /></motion.div>
                                        </a>
                                    </SwiperSlide>
                                )
                            }
                        }) : null
                    }
                </Swiper>
            </motion.div>

            <motion.div {...markaMotion}>
                <Swiper
                    className='w-100'
                    modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                    navigation
                    slidesPerView={2}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    scrollbar={{ draggable: true }}
                >
                    {
                        marka.map((item, index) => {
                            return (
                                <SwiperSlide key={index} onClick={() => window.location.href = `/product/${item.Marka_Id}/${item.Marka_Baslik}/1`}>
                                    <div className='brandItem'>
                                        <div className='brandImg'><img src={item.Medya_Ad === null ? logo : `${brandUrl}${item.Medya_Ad}`} /></div>
                                        <div className='brandText'><h4>{item.Marka_Baslik}</h4></div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </motion.div>

            <motion.div {...vitrinCategoriesVariant}>
                {
                    vitrinData !== null && vitrinData !== undefined ? vitrinData.map((item, index) => {
                        return (
                            <motion.div {...imgInitVariant} key={index} className='border shadow float-left mb-3' style={{ width: '44%', marginLeft: '4%', height: 165 }} onClick={() => window.location.href = `/product/${item.UrunKategori_Id}/${item.UrunKategori_Baslik}/0`}>
                                <img className='d-none' src={item.Medya_Ad} onLoad={imageLoaded} alt='' />
                                <div><img src={item.Medya_Ad} alt='' width='100%' onLoad={imageLoaded} /></div>
                                <div className='text-center text-secondary mt-2 mb-2 d-flex justify-content-center align-items-center' style={{ height: 47 }}><span style={{ fontSize: 15 }}>{item.UrunKategori_Baslik}</span></div>
                            </motion.div>
                        )
                    }) : null
                }
            </motion.div>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <div style={{ height: 750 }}></div>
        </motion.div > : <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <img src={loadImg} alt='' height={30} />
        </div>
    )
}

export default Categories