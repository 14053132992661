import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import '../../css/style.css'
import { formVariant, mobilVariant } from '../../components/animation'
import axios from 'axios'
import { adresduzenle, adresigetir, districtList, musteriadresaydet } from '../../api/api'
import BottomBar from '../../components/bottombar'
import { Form, Input, Button, Modal } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { adresTuru, cities } from '../../components/options'
import { encode } from '../../components/general'
import MenuHeader from '../../components/menuHeader'
import { XCircle } from 'react-feather'

const animatedComponents = makeAnimated();

const AddressEdit = ({ basketLengthh }) => {
    const [AddressType, setAddressType] = useState('1')
    const [city, setCity] = useState('')
    const [currentStatus1, setCurrentStatus1] = useState({})
    const [currentStatus2, setCurrentStatus2] = useState({})
    const [selectDistrict, setSelectDistrict] = useState({})
    const [dangerOpen, setDangerOpen] = useState(false)
    const [districtOpt, setDistrictOpt] = useState([])
    const [danger, setDanger] = useState('')
    const [data, setData] = useState([])
    const { handleSubmit } = useForm()
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const { id } = useParams()

    const getDistrict = (id) => {
        axios.get(`${districtList}${window.btoa(id)}`).then(res => {
            console.log(res.data)
            const newList = []
            if (res.data !== null) {
                for (let i = 0; i < res.data.length; i++) {
                    newList.push({ label: res.data[i].Usis_Ad, value: res.data[i].Usis_Id })
                }
                setDistrictOpt(newList)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }


    const getData = () => {
        if (id !== '0') {
            axios.get(`${adresigetir}${window.btoa(id)}`).then(res => {
                if (res.status === 200) {
                    if (res.data !== undefined && res.data !== null) {
                        const value = res.data[0]
                        setData(value)
                        setCurrentStatus1({ label: value.MusteriAdres_Tur, label: value.MusteriAdres_Tur === 1 ? 'Teslimat Adresi' : 'Fatura Adresi', value: value.MusteriAdres_Tur })
                        setCurrentStatus2({ label: value.MusteriAdres_SehirAd, value: value.MusteriAdres_Sehir })
                        setSelectDistrict({ label: value.MusteriAdres_IlceAd, value: value.MusteriAdres_Ilce })
                        setCity(value.MusteriAdres_SehirAd)
                        setAddressType(value.MusteriAdres_Tur === 1 ? '1' : '2')
                        getDistrict(value.MusteriAdres_Sehir)
                    }
                }
            }).catch(er => {
                setErrorModal(!errorModal)
                setErrorMessage(er.message)
            })
        }
    }

    const onSubmit = (values, e) => {
        const addressName = e.target[0].value
        const name = e.target[2].value
        const surname = e.target[3].value
        const address = e.target[6].value
        const phone = e.target[7].value
        const defaultAddress = e.target[8].checked

        axios.get(`${id !== '0' ? adresduzenle : musteriadresaydet}${id !== '0' ? encode(addressName) : window.btoa(unescape(encodeURIComponent(addressName)))},${id !== '0' ? encode(AddressType) : window.btoa(unescape(encodeURIComponent(AddressType)))},${id !== '0' ? encode(name) : window.btoa(unescape(encodeURIComponent(name)))},${id !== '0' ? encode(surname) : window.btoa(unescape(encodeURIComponent(surname)))},${id !== '0' ? encode(city) : window.btoa(unescape(encodeURIComponent(city)))},${id !== '0' ? encode(selectDistrict.value) : window.btoa(unescape(encodeURIComponent(selectDistrict.value)))},${id !== '0' ? encode(address) : window.btoa(unescape(encodeURIComponent(address)))},${id !== '0' ? encode(phone) : window.btoa(unescape(encodeURIComponent(phone)))},${!defaultAddress ? id !== 0 ? encode('Hayır') : window.btoa(unescape(encodeURIComponent('Hayır'))) : id !== '0' ? encode('Evet') : window.btoa(unescape(encodeURIComponent('Evet')))},${window.btoa(localStorage.getItem('auth'))},${window.btoa(`${id}`)}${id !== '0' ? null : `,${window.btoa(unescape(encodeURIComponent(false)))}`}`).then(res => {
            if (res.status === 200) {
                setDangerOpen(!dangerOpen)
                setDanger(res.data[0].sonuc)
                if (res.data[0].durum === "0" || res.data[0].durum === "1") {
                    setTimeout(() => {
                        window.location.href = "/address"
                    }, 1000)
                }
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <motion.div {...mobilVariant} className='address-edit'>
            <MenuHeader basket={basketLengthh} title={'ADRES DÜZENLE'} isSearch={false} />

            <motion.div className='duzenle' {...formVariant}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <span>Adres Adı</span>
                    <Input type='input' defaultValue={id !== '0' ? data.MusteriAdres_Baslik : null} />
                    <span>Adres Türü</span>
                    <Select
                        noOptionsMessage={() => 'Adres Türü Bulunamadı!'}
                        isClearable={false}
                        className="react-select"
                        classNamePrefix="select"
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        options={adresTuru}
                        components={animatedComponents}
                        value={currentStatus1}
                        onChange={data => {
                            setCurrentStatus1(data);
                            setAddressType(data.value);
                        }}
                    />
                    <span>Ad</span>
                    <Input type='input' defaultValue={id !== '0' ? data.MusteriAdres_Ad : null} />
                    <span>Soyad</span>
                    <Input type='input' defaultValue={id !== '0' ? data.MusteriAdres_Soyad : null} />
                    <span>Şehir</span>
                    <Select
                        noOptionsMessage={() => 'Şehir Bulunamadı!'}
                        isClearable={false}
                        className="react-select mb-2"
                        classNamePrefix="select"
                        options={cities}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        components={animatedComponents}
                        value={currentStatus2}
                        onChange={data => {
                            setCurrentStatus2(data);
                            setCity(data.value)
                            getDistrict(data.value)
                        }}
                    />
                    <span>İlçe</span>
                    <Select
                        noOptionsMessage={() => 'Şehir seçiniz!'}
                        isClearable={false}
                        className="react-select mb-2"
                        classNamePrefix="select"
                        options={districtOpt}
                        isDisabled={districtOpt.length !== 0 ? false : true}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        components={animatedComponents}
                        value={selectDistrict}
                        onChange={data => {
                            setSelectDistrict(data);
                        }}
                    />
                    <span>Açık Adres</span>
                    <Input type='textarea' style={{ fontSize: 13 }} defaultValue={id !== '0' ? data.MusteriAdres_Adres : null} />
                    <span>Telefon</span>
                    <Input type='tel' defaultValue={id !== '0' ? data.MusteriAdres_Telefon : null} />
                    <div><span>Varsayılan Adres</span></div>
                    <div>
                        <label className="switch">
                            <Input type="checkbox" defaultChecked={id !== '0' ? data.MusteriAdres_Varsayilan : null} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <Button>ADRESİ KAYDET</Button>
                </Form>
            </motion.div>

            <Modal isOpen={dangerOpen} toggle={() => setDangerOpen(!dangerOpen)} className='modal-dialog-centered'>
                <div className='p-2'>
                    <div><span style={{ fontSize: 13 }}>{danger}</span></div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button className='bg-primary border-0' style={{ fontSize: 13, borderRadius: 15 }} onClick={() => setDangerOpen(!dangerOpen)}>
                            Tamam</Button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLengthh} />
        </motion.div>
    )
}

export default AddressEdit