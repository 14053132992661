import React, { useState } from 'react'
import { mobilVariant, accountVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import BottomBar from '../../components/bottombar'
import axios from 'axios'
import { uyeSilme, uyebilgileri } from '../../api/api'
import MenuHeader from '../../components/menuHeader'
import { Button, Modal } from 'reactstrap'
import { XCircle } from 'react-feather'
import { useEffect } from 'react'

const Account = ({ basketLength }) => {
    const [modal, setModal] = useState(false)
    const [statusModal, setStatusModal] = useState(false)
    const [statusMessage, setStatusMessage] = useState("")
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [data, setData] = useState([])

    const delAccount = () => {
        const value = JSON.parse(localStorage.getItem('info'))
        axios.get(`${uyeSilme}${window.btoa(localStorage.getItem('auth'))},${window.btoa(value.MusteriAd)},${window.btoa(value.MusteriSoyad)},${window.btoa(value.MusteriTelefon)},${window.btoa(value.MusteriDogumTarihi)},${window.btoa(value.MusteriSifre)},${window.btoa(value.MusteriSifre)},${window.btoa(value.MusteriFirmaUnvani)},${window.btoa(value.MusteriVergiNumarasi)},${window.btoa(value.MusteriVergiDairesi)}`).then(res => {
            if (res.data[0].durum === "1") {
                setTimeout(() => { setModal(!modal) }, 600)
                setStatusMessage(res.data[0].sonuc)
                setStatusModal(!statusModal)
                setTimeout(() => {
                    setStatusModal(!statusModal)
                }, 1400)
                setTimeout(() => {
                    localStorage.clear()
                    window.location.href = '/login'
                }, 1200)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => {
        axios.get(`${uyebilgileri}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.data !== undefined && res.data !== null) {
                setData(res.data)
            }
        })
    }, [])

    return (
        <motion.div {...mobilVariant} className='account'>
            <MenuHeader title={'PROFİLİM'} isSearch={false} basket={basketLength} />

            {data[0] !== undefined ? <div className='name'>{`${data[0].Musteri_Ad} ${data[0].Musteri_Soyad}`}</div> : null}

            <motion.div {...accountVariant} className='menus'>
                <div onClick={() => window.location.href = '/orders'}><span>SİPARİŞLERİM</span></div>
                <div onClick={() => window.location.href = '/address'}><span>ADRESLERİM</span></div>
                <div onClick={() => window.location.href = '/profile'}><span>HESABIM</span></div>
                <div onClick={() => window.location.href = '/my-payments'}><span>ÖDEMELERİM</span></div>
                <div onClick={() => window.location.href = '/extract'}><span>HESAP EKSTRE</span></div>
                <div onClick={() => window.location.href = '/pay'}><span>ÖDEME YAP</span></div>
                <div onClick={() => window.location.href = '/about'}><span>TECH-IN HAKKINDA</span></div>
                <div onClick={() => setModal(!modal)}><span>HESABI SİL</span></div>
                <div onClick={() => { localStorage.clear(); window.location.reload() }}><span>ÇIKIŞ</span></div>
            </motion.div>

            <BottomBar basket={basketLength} />

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div className='text-center'><h5>Asil Otogaz</h5></div>
                    <div><small>Hesabınızı silmek istediğinize emin misiniz?</small></div>
                    <div className='d-flex justify-content-around mt-3'>
                        <div className='w-50'>
                            <Button color='danger' className='p-1 w-100' onClick={() => delAccount()}>Evet</Button>
                        </div>
                        <div className='w-50'>
                            <Button color='primary' className='p-1 w-100' onClick={() => setModal(!modal)}>Hayır</Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={statusModal} toggle={() => setStatusModal(!statusModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div className='text-center'><h5>Asil Otogaz</h5></div>
                    <div className='mt-3 mb-2'><small>{statusMessage}</small></div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>
        </motion.div>
    )
}

export default Account