import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import Login from '../pages/auth/login/index'
import Register from '../pages/auth/register'
import HomePage from '../pages/homepage'
import Contracts from '../pages/contract'
import Account from '../pages/account'
import Search from '../pages/search'
import Orders from '../pages/orders'
import Pay from '../pages/pay'
import Product from '../pages/product'
import Basket from '../pages/basket'
import AddressInfo from '../pages/basket/addressInfo'
import PayInfo from '../pages/basket/payInfo'
import ProductDetail from '../pages/product/productdetail'
import axios from 'axios'
import { sepetigetir } from '../api/api'
import OrderDetail from '../pages/orders/orderdetail'
import Address from '../pages/address/address'
import AddressEdit from '../pages/address/addressedit'
import Profile from '../pages/profile/account'
import MyPayments from '../pages/my-payments/mypayments'
import Extracts from '../pages/extract'
import About from '../pages/about/about'
import TreeD from '../pages/treed'
import { Modal } from 'reactstrap'
import { XCircle } from 'react-feather'
import ForgetPassword from '../pages/auth/forgetPassword'

const AnimatedRoutes = () => {
    const [basketLength, setBasketLength] = useState('0')
    const location = useLocation()
    const isLogin = localStorage.getItem('auth')
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (isLogin !== undefined && isLogin !== null) {
            axios.get(`${sepetigetir}${window.btoa(isLogin)}`).then(res => {
                if (res.status === 200) {
                    const value = res.data
                    setBasketLength(`${value.length}`)
                }
            }).catch(er => {
                setErrorModal(!errorModal)
                setErrorMessage(er.message)
            })
        }
    }, [])

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={isLogin === undefined || isLogin === null ? <Login /> : <HomePage basketLength={basketLength} />} />
                <Route path='/login' element={isLogin === undefined || isLogin === null ? <Login /> : <HomePage basketLength={basketLength} />} />
                <Route path='/register' element={<Register />} />
                <Route path='/forgetPassword' element={<ForgetPassword />} />
                <Route path='/contracts/:id' element={<Contracts />} />
                <Route path='/account' element={isLogin === undefined || isLogin === null ? <Login /> : <Account basketLength={basketLength} />} />
                <Route path='/search/:searchWord/:worded' element={isLogin === undefined || isLogin === null ? <Login /> : <Search basketLength={basketLength} />} />
                <Route path='/orders' element={isLogin === undefined || isLogin === null ? <Login /> : <Orders basketLength={basketLength} />} />
                <Route path='/address' element={isLogin === undefined || isLogin === null ? <Login /> : <Address basketLength={basketLength} />} />
                <Route path='/pay' element={isLogin === undefined || isLogin === null ? <Login /> : <Pay basketLength={basketLength} />} />
                <Route path='/product/:id/:name/:isBrand' element={isLogin === undefined || isLogin === null ? <Login /> : <Product basketLengthh={basketLength} />} />
                <Route path='/basket' element={isLogin === undefined || isLogin === null ? <Login /> : <Basket basketLength={basketLength} />} />
                <Route path='/address-info' element={isLogin === undefined || isLogin === null ? <Login /> : <AddressInfo basketLength={basketLength} />} />
                <Route path='/pay-info' element={isLogin === undefined || isLogin === null ? <Login /> : <PayInfo basketLength={basketLength} />} />
                <Route path='/product-detail/:id' element={isLogin === undefined || isLogin === null ? <Login /> : <ProductDetail basketLengthh={basketLength} />} />
                <Route path='/order-detail/:id' element={isLogin === undefined || isLogin === null ? <Login /> : <OrderDetail basketLengthh={basketLength} />} />
                <Route path='/address-edit/:id' element={isLogin === undefined || isLogin === null ? <Login /> : <AddressEdit basketLengthh={basketLength} />} />
                <Route path='/profile' element={isLogin === undefined || isLogin === null ? <Login /> : <Profile basketLengthh={basketLength} />} />
                <Route path='/my-payments' element={isLogin === undefined || isLogin === null ? <Login /> : <MyPayments basketLengthh={basketLength} />} />
                <Route path='/extract' element={isLogin === undefined || isLogin === null ? <Login /> : <Extracts basketLengthh={basketLength} />} />
                <Route path='/about' element={isLogin === undefined || isLogin === null ? <Login /> : <About basketLengthh={basketLength} />} />
                <Route path='/treed' element={isLogin === undefined || isLogin === null ? <Login /> : <TreeD />} />
            </Routes>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>
        </AnimatePresence>
    )
}

export default AnimatedRoutes