import React, { useEffect, useState } from 'react'
import '../../css/style.css'
import { motion } from 'framer-motion'
import BottomBar from '../../components/bottombar'
import { Input, Row, Col, Modal } from 'reactstrap'
import searchimg from '../../img/search.png'
import loadimg from '../../img/loading.svg'
import { mobilVariant, searchBrandVariant } from '../../components/animation'
import MenuHeader from '../../components/menuHeader'
import axios from 'axios'
import { aramayap } from '../../api/api'
import { useParams } from 'react-router-dom'
import { XCircle } from 'react-feather'
import { toUtf8 } from '../../components/general'

const Search = ({ basketLength }) => {
    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)
    const { searchWord, worded } = useParams()
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [searchValue, setSearchValue] = useState("")

    const getSearch = (isFirst) => {
        setData([])
        const newWord = toUtf8(searchValue)
        setLoad(false)

        console.log(newWord)
        console.log(searchValue)

        if (newWord.length > 2 && newWord !== "") {
            axios.get(`${aramayap}${window.btoa(newWord)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
                if (res.data !== undefined) {
                    setData(res.data)
                    setLoad(true)
                }
            }).catch(er => {
                setErrorModal(!errorModal)
                setErrorMessage(er.message)
            })
        }
    }

    useEffect(() => {
        if (searchWord !== '0') {
            setLoad(false)
            getSearch(decodeURI(searchWord), worded)
        }
    }, [])

    return (
        <motion.div {...mobilVariant} className='searchpage'>
            <MenuHeader title={'ARAMA YAP'} isSearch={true} basket={basketLength} />

            <div style={{ display: 'flex', justifyContent: 'start', marginTop: 65, width: '100%', borderBottom: '1px solid rgb(222, 222, 222)' }}>
                <div style={{ width: '86%' }}><Input type='text' className='border-0' placeholder='Aranacak kelime...' defaultValue={worded !== 'bos' ? worded !== '0' ? decodeURI(worded) : null : null}
                    onBlur={e => {
                        setSearchValue(e.target.value)
                    }} /></div>
                <div style={{ width: '14%', paddingTop: 4 }} onClick={() => getSearch(false)}><img src={searchimg} alt='' height={25} style={{ marginLeft: 10 }} /></div>
            </div>

            {load ? <motion.div {...searchBrandVariant}>
                <Row className='w-100 p-0 m-0'>
                    {
                        data.map((item, index) => {
                            return (
                                item !== undefined ? <Col sm={6} md={6} xl={6} key={index} className='w-50 text-center mb-3' onClick={() => window.location.href = `/product-detail/${item.Urun_Id}`}>
                                    <img src={item.Resim} alt='' height={140} style={{ display: 'none', maxWidth: 150, zIndex: -1, position: 'relative' }} />
                                    <div style={{ border: '1px solid rgb(220, 220, 220)', borderRadius: 15, padding: 10, width: '100%' }}>
                                        <div><img src={item.Resim} alt='' height={140} style={{ maxWidth: 150, zIndex: -1, position: 'relative' }} /></div>
                                        <div className='productName'><small>{item.Urun_Baslik}</small></div>
                                        {item.Renkler !== null ? <div className='d-flex justify-content-center'>
                                            {
                                                item.Renkler.split(',').map((i, j) => {
                                                    return (
                                                        j < 5 ? <div style={{ width: 20, height: 20, borderRadius: 10, marginLeft: 2, marginRight: 2, border: '1px solid grey', alignItems: 'center', display: 'flex', justifyContent: 'center' }} key={j}>
                                                            <div style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: i }}></div>
                                                        </div> : null
                                                    )
                                                })
                                            }
                                        </div> : <div className='d-flex justify-content-center'><div style={{ width: 20, height: 20, borderRadius: 10, marginLeft: 2, marginRight: 2, border: '1px solid grey', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: 'white' }}></div>
                                        </div></div>}
                                        <div><small style={{ fontSize: 11 }}>Ürün Kodu: {item.Urun_TicariYazilimId}</small></div>
                                        <div className='text-secondary'><del>{item.Etiket} {item.DovizTipi}</del></div>
                                        <div><span>{item.Perakende} {item.DovizTipi}</span></div>
                                    </div>
                                </Col> : null
                            )
                        })
                    }
                </Row>
            </motion.div> : <div className='d-flex justify-content-center align-items-center text-center w-100' style={{ height: '80vh' }}>
                <div><img src={loadimg} alt='' height={40} /></div>
            </div>}

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default Search