import React, { useEffect, useState } from 'react'
import { mobilVariant, addressVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import { odemeGecmisi } from '../../api/api'
import axios from 'axios'
import BottomBar from '../../components/bottombar'
import { Input, Modal } from 'reactstrap'
import print from '../../img/print.png'
import MenuHeader from '../../components/menuHeader'
import { XCircle } from 'react-feather'

const MyPayments = ({ basketLengthh }) => {
    const [data, setData] = useState([])
    const [initDate, setInitDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getDate = () => {
        axios.get(`${odemeGecmisi}${window.btoa(localStorage.getItem('auth'))},${window.btoa(initDate)},${window.btoa(endDate)}`).then(res => {
            if (res.status === 200) {
                setData(res.data)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => { getDate() }, [])

    return (
        <motion.div {...mobilVariant} className='my-payments'>
            <MenuHeader title={'ÖDEMELERİM'} basket={basketLengthh} isSearch={false} />

            <div className='date-print'>
                <div>
                    <Input type='date' onChange={e => { setInitDate(e.target.value); getDate(); }} />
                </div>
                <div>
                    <Input type='date' onChange={e => { setEndDate(e.target.value); getDate(); }} />
                </div>
                <div>
                    <img src={print} alt='' />
                </div>
            </div>

            <div id='tableview'>
                {data !== undefined && data !== null ? data.length !== 0 ? <div className='tables'>
                    <div><b>ÖDEME NO</b></div>
                    <div><b>ÖDEME TARİHİ</b></div>
                    <div><b>DURUM</b></div>
                    <div><b>TOPLAM TUTAR</b></div>
                </div> : null : null}

                <motion.div {...addressVariant}>
                    {data !== undefined && data !== null ? data.length !== 0 ? <div className='tabless'>
                        {
                            data.map((item, index) => {
                                return (
                                    <div className='item' key={index}>
                                        <div><small>{item.OzelOdeme_Id}</small></div>
                                        <div style={{ marginLeft: 20 }}><small>{new Date(item.OzelOdeme_Tarih).toLocaleDateString()}</small></div>
                                        <div style={{ marginLeft: 20 }}><small>{item.OzelOdeme_OdemeDurum}</small></div>
                                        <div style={{ marginLeft: 20 }}><small>{item.OzelOdeme_OdemeTutar}₺</small></div>
                                    </div>
                                )
                            })
                        }
                    </div> : null : null}
                </motion.div>
            </div>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLengthh} />
        </motion.div>
    )
}

export default MyPayments