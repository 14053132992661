import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import '../../css/style.css'
import { addressVariant, mobilVariant } from '../../components/animation'
import BottomBar from '../../components/bottombar'
import { musteriadresler, musteriadressil } from '../../api/api'
import axios from 'axios'
import sil from '../../img/sil.png'
import duzenle from '../../img/duzenle.png'
import { Button, Modal } from 'reactstrap'
import MenuHeader from '../../components/menuHeader'
import { XCircle } from 'react-feather'

const Address = ({ basketLength }) => {
    const [modal, setModal] = useState(false)
    const [selectId, setSelectId] = useState(0)
    const [data, setData] = useState([])
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getData = () => {
        axios.get(`${musteriadresler}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                setData(res.data)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const deleteAddress = () => {
        axios.get(`${musteriadressil}${window.btoa(selectId)}`).then(res => {
            if (res.status === 200) {
                setModal(!modal)
                getData()
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='my-address'>
            <MenuHeader basket={basketLength} title={'ADRESLERİM'} isSearch={false} />

            <div className='basliklar'>
                <div><b>ADRES BAŞLIK</b></div>
                <div><b>ADRES TÜRÜ</b></div>
                <div><b>İŞLEM</b></div>
            </div>

            <motion.div className='addresses' {...addressVariant}>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className='item'>
                                <div><small>{item.MusteriAdres_Baslik}</small></div>
                                <div><small>{item.MusteriAdresTur_Ad}</small></div>
                                <div className='d-flex justify-content-around'>
                                    <div onClick={() => window.location.href = `/address-edit/${item.MusteriAdres_Id}`}><img src={duzenle} alt='' /></div>
                                    <div className='ml-3' onClick={() => { setSelectId(item.MusteriAdres_Id); setModal(!modal) }}>
                                        <img src={sil} alt='' />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </motion.div>


            <Button onClick={() => window.location.href = '/address-edit/0'}>ADRES EKLE</Button>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><b>Adres Sil</b></div>
                    <div className='mt-2'><small>Adresi silmek istediğinize emin misiniz?</small></div>
                    <div className='d-flex justify-content-end mt-2'>
                        <Button className='border border-primary bg-white text-primary' style={{ fontSize: 13 }} onClick={() => setModal(!modal)}>Hayır</Button>
                        <Button className='bg-primary ml-2 border-0' style={{ fontSize: 13 }} onClick={() => deleteAddress()}>Evet</Button>
                    </div>
                </div>
            </Modal>
            <BottomBar basket={basketLength} />

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>
        </motion.div >
    )
}

export default Address