import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import '../../css/style.css'
import { formVariant, mobilVariant, nullBasketVariant } from '../../components/animation'
import MenuHeader from '../../components/menuHeader'
import BottomBar from '../../components/bottombar'
import axios from 'axios'
import { sepetAdetGuncelle, sepetiBosalt, sepetigetir, sepettensil } from '../../api/api'
import { Button, Card, Input, Modal } from 'reactstrap'
import loadImg from '../../img/loading.svg'
import { ChevronLeft, Meh, Trash2, X, XCircle } from 'react-feather'

const Basket = ({ basketLength }) => {
    const [data, setData] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteBasketModal, setDeleteBasketModal] = useState(false)
    const [nullBasket, setNullBasket] = useState(false)
    const [price, setPrice] = useState(0)
    const [selectedId, setSelectedId] = useState(0)
    const [viewImage, setViewImage] = useState(false)
    const [basketEditModal, setBasketEditModal] = useState(false)
    const [basketEditMessage, setBasketEditMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getData = () => {
        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            let pricetotal = 0
            const value = res.data
            setData(value)
            for (let i = 0; i < value.length; i++) {
                const newValue = value[i].Perakende * value[i].Sepet_Miktar
                pricetotal += newValue
            }
            setPrice(pricetotal)
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const deleteProduct = () => {
        axios.get(`${sepettensil}${window.btoa(selectedId)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                getData()
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const nullBasketData = () => {
        axios.get(`${sepetiBosalt}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                getData()
            }
            setNullBasket(!nullBasket)
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
            setNullBasket(!nullBasket)
        })
    }

    const basketEdit = (id, adet, renk, beden) => {
        axios.get(`${sepetAdetGuncelle}${window.btoa(id)},${window.btoa(adet)},${window.btoa(localStorage.getItem('auth'))},${window.btoa(renk)},${window.btoa(beden)}`).then(res => {
            setBasketEditMessage(res.data[0].sonuc)
            setBasketEditModal(!basketEditModal)
            getData()
            setTimeout(() => {
                setBasketEditModal(false)
            }, 2000);
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='basket'>
            <MenuHeader title='SEPETİM' isSearch={false} basket={basketLength} />

            {loading ? data.length !== 0 ? <motion.div {...formVariant}>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className={selectedId === item.Urun_Id && deleteBasketModal ? 'deleteBasketModal d-flex justify-content-start align-items-center p-3 shadow mb-3' : 'd-flex justify-content-start align-items-center p-3 shadow mb-3'} style={{ border: '1px solid rgb(207, 207, 207)' }}>
                                <div><img src={item.Medya} alt='' width={100} /></div>
                                <div style={{ width: 180, marginLeft: 10 }}>
                                    <div><small className='text-secondary'>{item.Urun_Baslik}</small></div>
                                    <div><small style={{ fontSize: 12, color: 'rgb(208, 208, 208)' }}>Ürün Kodu: {item.Urun_TicariYazilimId}</small></div>
                                    <div><span>{item.Perakende} {item.DovizTipi}</span></div>
                                </div>
                                <div className='mr-2'>
                                    <div><small>ADET</small></div>
                                    <div><Input type='number' defaultValue={item.Sepet_Miktar} style={{ width: 40 }} onChange={e => {
                                        setTimeout(() => {
                                            basketEdit(item.Urun_Id, e.target.value, item.Sepet_UrunRenkId, item.Sepet_UrunBedenId)
                                        }, 1500)
                                    }} /></div>
                                    {viewImage ? <div className={item.Urun_Id === selectedId ? 'animationimg' : 'notimg'}>
                                        <div className='arti1'><img src={item.Medya} height={40} width={40} style={{ borderRadius: 20 }} alt='' /></div>
                                    </div> : null}

                                </div>
                                <div onClick={() => { setDeleteBasketModal(!deleteBasketModal); setSelectedId(item.Urun_Id) }}>
                                    {selectedId === item.Urun_Id && deleteBasketModal ? <ChevronLeft size={24} color='grey' /> : <X size={24} color='grey' />}
                                </div>

                                {selectedId === item.Urun_Id && deleteBasketModal ? <div className='ml-3'>
                                    <Button color='danger' onClick={() => { setSelectedId(item.Urun_Id); setDeleteModal(!deleteModal) }}>
                                        <div className='text-center'>
                                            <div><Trash2 size={20} /></div>
                                            <div><span>Sil</span></div>
                                        </div>
                                    </Button>
                                </div> : null}
                            </div>
                        )
                    })
                }
            </motion.div> : <motion.div {...nullBasketVariant} className='nullbasket' style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <div>
                    <div className='text-center border border-secondary rounded text-secondary p-3'>
                        <div><Meh size={30} /></div>
                        <div className='mt-2'><span>Sepetinizde Ürün Bulunamadı</span></div>
                    </div>
                    <Button className='bg-white border border-secondary text-secondary mt-3' onClick={() => window.location.href = '/'}><ChevronLeft size={18} className='mr-2' /><small>Ana Sayfa</small></Button>
                </div>
            </motion.div> : <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <img src={loadImg} alt='' height={30} />
            </div>}

            <div className='tamamla'>
                <Card className='p-1 text-center' style={{ border: '1px solid #242d41', borderRadius: 10 }}><div><b><span>Toplam: </span><span>{price} $</span></b></div></Card>
                <div className='w-100 d-flex mt-2'>
                    <div className='w-50'>
                        <Button className='bosalt' onClick={() => setNullBasket(!nullBasket)}>SEPETİ BOŞALT</Button>
                    </div>
                    <div className='w-50'>
                        <Button className='satin' onClick={() => window.location.href = '/address-info'}>SATIN AL</Button>
                    </div>
                </div>
            </div>

            <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><b>Ürün Sil</b></div>
                    <div><small>Sepetten ürünü silmek istediğinize emin misiniz?</small></div>
                    <div className='d-flex justify-content-end mt-2'>
                        <div><Button className='border border-primary bg-white text-primary' style={{ borderRadius: 15, fontSize: 13 }} onClick={() => { setDeleteModal(!deleteModal); setDeleteBasketModal(!deleteBasketModal) }}>Hayır</Button></div>
                        <div className='ml-2'><Button className='bg-primary border-0' onClick={() => { setDeleteModal(!deleteModal); setDeleteBasketModal(!deleteBasketModal); deleteProduct() }} style={{ borderRadius: 15, fontSize: 13 }}>Evet</Button></div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={nullBasket} toggle={() => setNullBasket(!nullBasket)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><b>Sepeti Boşalt</b></div>
                    <div><small>Sepeti boşaltmak istediğinize emin misiniz?</small></div>
                    <div className='d-flex justify-content-end mt-2'>
                        <div><Button className='border border-primary bg-white text-primary' style={{ borderRadius: 15, fontSize: 13 }} onClick={() => setNullBasket(!nullBasket)}>Hayır</Button></div>
                        <div className='ml-2'><Button className='bg-primary border-0' onClick={() => nullBasketData()} style={{ borderRadius: 15, fontSize: 13 }}>Evet</Button></div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={basketEditModal} toggle={() => setBasketEditModal(!basketEditModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setBasketEditModal(!basketEditModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>{basketEditMessage}</small></div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default Basket