import React from 'react'
import back from '../img/next.png'
import search from '../img/search.png'
import shopping from '../img/shopping.png'

const MenuHeader = ({ title, isSearch, basket }) => {
    const isLogin = localStorage.getItem('isLogin')

    return (
        <div className='d-flex justify-content-between bg-white w-100 align-items-center pl-3 pr-3 header' style={{ border: isSearch ? 0 : '1px solid rgb(222, 222, 222)', height: 60, position: 'fixed', top: 0, left: 0, zIndex: 99999 }}>
            <div className='backButton shadow' onClick={() => window.history.back()}><img src={back} alt='' height={20} /></div>
            <div className='text-center text-secondary'><span style={{ fontSize: 18 }}>{title}</span></div>
            <div className='d-flex justify-content-center position-relative'>
                {isSearch ? null : <div onClick={() => window.location.href = '/search/0/bos'}><img src={search} alt='' height={30} /></div>}
                <div className='pl-3'><img src={shopping} alt='' height={30} /></div>
                <div className='basket' onClick={() => window.location.href = '/basket'}><span>{isLogin !== '0' ? basket : '0'}</span></div>
            </div>
        </div>
    )
}

export default MenuHeader