import React, { useState } from 'react'
import { formVariant, mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import BottomBar from '../../components/bottombar'
import { uyeProfilBilgileriGuncelle, uyebilgileri } from '../../api/api'
import axios from 'axios'
import { Form, Input, Button, Modal } from 'reactstrap'
import { useForm } from 'react-hook-form'
import MenuHeader from '../../components/menuHeader'
import { XCircle } from 'react-feather'
import { useEffect } from 'react'

const Profile = ({ basketLengthh }) => {
    const [dangerOpen, setDangerOpen] = useState(false)
    const [danger, setDanger] = useState('')
    const { handleSubmit } = useForm()
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState("")
    const [successModal, setSuccessModal] = useState(false)
    const [data, setData] = useState([])

    const getData = () => {
        axios.get(`${uyebilgileri}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.data !== undefined && res.data !== null) {
                setData(res.data)
            }
        })
    }

    const onSubmit = (values, e) => {
        const name = window.btoa(unescape(encodeURIComponent(e.target[0].value)))
        const surname = window.btoa(unescape(encodeURIComponent(e.target[1].value)))
        const date = window.btoa(unescape(encodeURIComponent(e.target[2].value)))
        const phone = window.btoa(unescape(encodeURIComponent(e.target[3].value)))
        const unvan = window.btoa(unescape(encodeURIComponent(e.target[4].value)))
        const vergiD = window.btoa(unescape(encodeURIComponent(e.target[5].value)))
        const vergiN = window.btoa(unescape(encodeURIComponent(e.target[6].value)))
        const pass1 = window.btoa(unescape(encodeURIComponent(e.target[7].value)))
        const pass2 = window.btoa(unescape(encodeURIComponent(e.target[8].value)))

        axios.get(`${uyeProfilBilgileriGuncelle}${window.btoa(localStorage.getItem('auth'))},${name},${surname},${phone},${date},${pass1},${pass2},${unvan},${vergiD},${vergiN}`).then(res => {
            if (res.status === 200) {
                if (res.data[0].durum === '0') {
                    setDangerOpen(!dangerOpen)
                    setDanger(res.data[0].sonuc)
                    getData()
                }else{
                    setSuccessModal(!successModal)
                    setMessage(res.data[0].sonuc)
                    setTimeout(() => {
                        setSuccessModal(false)
                    }, 2000);
                }
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <motion.div {...mobilVariant} className='account'>
            <MenuHeader basket={basketLengthh} title={'HESABIM'} />

            <motion.div className='info' {...formVariant}>
                <div className='forms mt-4'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-2'>
                            <span>Ad</span>
                            <Input type='input' defaultValue={data[0] ? data[0].Musteri_Ad : ''} />
                        </div>
                        <div className='mb-2'>
                            <span>Soyad</span>
                            <Input type='input' defaultValue={data[0] ? data[0].Musteri_Soyad : ''} />
                        </div>
                        <div className='mb-2'>
                            <span>Doğum Tarihi</span>
                            <Input type='date' defaultValue={data[0] ? data[0].Musteri_DogumTarihi : ''} />
                        </div>
                        <div className='mb-2'>
                            <span>Cep Telefonu</span>
                            <Input type='tel' defaultValue={data[0] ? data[0].Musteri_Telefon : ''} />
                        </div>
                        <div className='mb-2'>
                            <span>Firma Ünvanı</span>
                            <Input type='input' defaultValue={data[0] ? data[0].Musteri_FirmaUnvani : ''} />
                        </div>
                        <div className='mb-2'>
                            <span>Vergi Dairesi</span>
                            <Input type='input' defaultValue={data[0] ? data[0].Musteri_VergiDairesi : ''} />
                        </div>
                        <div className='mb-2'>
                            <span>Vergi Numarası</span>
                            <Input type='tel' defaultValue={data[0] ? data[0].Musteri_VergiNumarasi : ''} />
                        </div>
                        <div className='mt-4 mb-2'>
                            <span className='text-dark'><b>Şifre Güncelleme</b></span>
                        </div>
                        <div className='mb-2'>
                            <span>Yeni Şifre</span>
                            <Input type='password' placeholder='*********' />
                        </div>
                        <div>
                            <span>Yeni Şifre(Tekrar)</span>
                            <Input type='password' placeholder='*********' />
                        </div>
                        <Button>GÜNCELLE</Button>
                    </Form>
                </div>
            </motion.div>

            <Modal isOpen={dangerOpen} toggle={() => setDangerOpen(!dangerOpen)} className='modal-dialog-centered'>
                <div className='p-2'>
                    <div><span style={{ fontSize: 13 }}>{danger}</span></div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button className='bg-danger border-0' style={{ fontSize: 13, borderRadius: 15 }} onClick={() => setDangerOpen(!dangerOpen)}>
                            Tamam</Button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={successModal} toggle={() => setSuccessModal(!successModal)} className='modal-dialog-centered'>
                <div className="p-2">
                    <div className='text-center mt-3'><h6>TECH-IN</h6></div>
                    <div className='ml-2 mr-2 text-center'><small>{message}</small></div>
                    <div className="d-flex justify-content-center">
                        <Button className="border border-primary bg-white text-primary mt-2 pl-3 pr-3 pt-1 pb-1" onClick={()=>setSuccessModal(!successModal)}>Tamam</Button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLengthh} />
        </motion.div>
    )
}

export default Profile