import React from 'react'
import logo from '../img/logo.png'
import shopping from '../img/shopping.png'
import search from '../img/search.png'

const Header = ({ basket }) => {
    const isLogin = localStorage.getItem('isLogin')

    return (
        <div className='header d-flex justify-content-between bg-white pt-2 pb-2 w-100' style={localStorage.getItem('drawer', true) ? { position: 'fixed', top: 0, left: 0, zIndex: 1 } : { position: 'fixed', top: 0, left: 0, zIndex: 2 }}>
            <div></div>
            <div className='pt-3'>
                <img src={logo} alt='' width={200} />
            </div>
            <div className='d-flex justify-content-center pt-3 position-relative'>
                <div className='mr-3' onClick={() => window.location.href = '/search/0/bos'}><img src={search} alt='' width={30} /></div>
                <div className='mr-3'><img src={shopping} alt='' width={30} /></div>
                <div className='baskets' onClick={() => window.location.href = '/basket'}><span>{isLogin !== '0' ? basket : '0'}</span></div>
            </div>
        </div>
    )
}

export default Header