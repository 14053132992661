import React, { useEffect, useState } from 'react'
import { homeSliderVariant, mobilVariant, vitrinCategoriesVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import { useParams } from 'react-router-dom'
import BottomBar from '../../components/bottombar'
import axios from 'axios'
import { sepeteekle, sepetigetir, urundetay } from '../../api/api'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { Button, Input, Modal } from 'reactstrap'
import { Check, ChevronLeft, ChevronRight, Minus, Plus, XCircle } from 'react-feather'
import kargo from '../../img/kargo.png'
import logoImg from '../../img/image.jpg'
import MenuHeader from '../../components/menuHeader'
import { convertTr } from '../../components/general'
import Barcode from 'react-barcode'

const ProductDetail = ({ basketLengthh }) => {
    const { id } = useParams()
    const [basketLength, setBasketLength] = useState(basketLengthh)
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [langImg, setLangImg] = useState('')
    const [basketEnter, setBasketEnter] = useState(false)
    const [notProduct, setNotProduct] = useState(false)
    const [productLength, setProductLength] = useState(1)
    const [selectedColor, setSelectedColor] = useState("1")
    const [selectedColorName, setSelectedColorName] = useState("")
    const [selectedColorNameKod, setSelectedColorNameKod] = useState("")
    const [selectedColorBarcoede, setSelectedColorBarcode] = useState("")
    const [selectedImg, setSelectedImg] = useState()
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [addLoad, setAddLoad] = useState(false)
    const [isNewBasket, setIsNewBasket] = useState(false)
    const [stokAdet, setStokAdet] = useState(0)
    const [isStok, setIsStok] = useState(false)

    const getData = () => {
        axios.get(`${urundetay}${window.btoa(id)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setData(value)
                if (value.Urun_Renkleri[0] !== undefined) {
                    setSelectedColor(value.Urun_Renkleri[0].Renk_Id)
                    setSelectedImg(value.Urun_Renkleri[0].Renk_Resmi)
                    setStokAdet(value.Urun_Renkleri[0].Renk_Stok)
                    setSelectedColorName(value.Urun_Renkleri[0].Renk_Baslik)
                    setSelectedColorNameKod(value.Urun_Renkleri[0].Renk_Kodu)
                    setSelectedColorBarcode(value.Urun_Renkleri[0].Renk_Barkod)
                } else {
                    setStokAdet(value.Urun_Bilgileri[0].Urun_StokMiktari)
                }
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const addBasket = () => {
        setBasketEnter(true)
        axios.get(`${sepeteekle}${window.btoa(id)},${window.btoa(localStorage.getItem('auth'))},${window.btoa('1')},${window.btoa(selectedColor)}`).then(res => {
            if (res.status === 200) {
                axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
                    if (res.status === 200) {
                        const value = res.data
                        setIsNewBasket(true)
                        setTimeout(() => {
                            setIsNewBasket(false)
                        }, 2000);
                        setBasketLength(`${value.length}`)
                    }
                })
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }


    useEffect(() => {
        getData()
        if (!basketEnter) {
            getData()
        }
    }, [])

    return (
        <motion.div {...mobilVariant} className='product-detail'>
            <MenuHeader title={'ÜRÜN DETAYI'} basket={isNewBasket ? basketLength : basketLengthh} isSearch={false} />

            <motion.div {...homeSliderVariant} className='slider'>
                {data.Urun_Resimleri !== undefined ? data.Urun_Resimleri.length !== 0 ? <Swiper
                    className='w-100'
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                >
                    {
                        data.Urun_Resimleri !== undefined ? data.Urun_Resimleri.map((item, index) => {
                            return (
                                <SwiperSlide key={index}><img src={item.kucukresim !== undefined ? item.kucukresim : logoImg} onClick={() => { setLangImg(item.buyukresim); setModal(!modal) }} /></SwiperSlide>
                            )
                        }) : <img src={logoImg} alt='' width='100%' />
                    }
                </Swiper> : null : null}
            </motion.div>

            <motion.div {...vitrinCategoriesVariant}>
                {data.Urun_Bilgileri !== undefined ? <div className='text-center mt-3'>
                    <div><span>{data.Urun_Bilgileri[0].Urun_Baslik}</span></div>
                    <div className='brandName mt-2 mb-2'><span onClick={() => window.location.href = `/product/${data.Urun_Bilgileri[0].Marka_Id}/${data.Urun_Bilgileri[0].Marka_Baslik}/1`}><ChevronLeft />{data.Urun_Bilgileri[0].Marka_Baslik}<ChevronRight /></span></div>
                    <div className='mt-1 text-secondary'><small>Ürün Kodu: </small><small>{data.Urun_Bilgileri[0].Urun_Kodu}</small></div>
                    <div className='d-flex justify-content-center'>
                        <div className='mr-3 text-secondary'><del>{data.Urun_Bilgileri[0].Urun_SatisFiyati} {data.Urun_Bilgileri[0].Urun_DovizTipi}</del></div>
                        <div><b>{data.Urun_Bilgileri[0].Urun_IndirimliFiyati} {data.Urun_Bilgileri[0].Urun_DovizTipi}</b></div>
                    </div>
                </div> : null}

                {
                    data.Urun_Renkleri !== undefined ? <div className='m-3 p-3 text-center border colorInfo'>
                        <div className='w-100 d-flex justify-content-center overflow-auto'>
                            {
                                data.Urun_Renkleri.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                setSelectedColor(item.Renk_Id);
                                                setStokAdet(item.Renk_Stok);
                                                setSelectedImg(item.Renk_Resmi);
                                                setSelectedColorName(item.Renk_Baslik);
                                                setSelectedColorBarcode(item.Renk_Barkod);
                                                setSelectedColorNameKod(item.Renk_Kodu);
                                            }}
                                            style={item.Renk_Id === selectedColor ? {
                                                height: 46,
                                                width: 46,
                                                borderRadius: 23,
                                                display: 'flex',
                                                position: 'relative',
                                                justifyContent: 'center',
                                                border: '1px solid #242d41',
                                                alignItems: 'center',
                                                float: 'left',
                                                textAlign: 'center',
                                                marginRight: 5,
                                                marginLeft: 5
                                            } : {
                                                height: 46,
                                                width: 46,
                                                borderRadius: 23,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                border: '1px solid #d3d3d3',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                float: 'left',
                                                marginRight: 5,
                                                marginLeft: 5
                                            }}>
                                            <img src={item.Renk_Resmi} alt='' style={item.Renk_Id === selectedColor ? { opacity: 0.2 } : { opacity: 1 }} height={30} />
                                            <Check size={30} color='#242d41' style={item.Renk_Id === selectedColor ? { position: 'absolute' } : { display: 'none' }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='w-100'><small style={{ color: 'grey' }}> {convertTr(selectedColorName)} </small></div>
                        <div><Barcode value={selectedColorBarcoede} width={1.5} height={55} /></div>
                    </div> : null
                }

                {data.Urun_Bilgileri !== undefined ? <div className='adet' style={{ position: 'relative' }}>
                    <div><Minus color='#242d41' onClick={() => {
                        if (productLength !== 1 && productLength > 1) {
                            setProductLength(productLength - 1)
                        } else {
                            if (productLength !== 1) {
                                setIsStok(!isStok)
                            }
                        }
                    }} /></div>
                    <div><Input type='number' value={productLength} onChange={e => setProductLength(e.target.value)} /></div>
                    <div><Plus color='#242d41' onClick={() => {
                        if (stokAdet > productLength) {
                            setProductLength(productLength + 1)
                        } else {
                            setIsStok(!isStok)
                        }
                    }} /></div>
                </div> : null}

                {data.Urun_Bilgileri !== undefined ? <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-end ml-3'><img src={selectedImg} alt='' height={40} className='p-1 border border-secondary rounded' /></div>
                    <div className='w-100 sepet-ekle'>
                        <Button onClick={() => {
                            if (stokAdet > 0) {
                                addBasket();
                                setAddLoad(true)
                                setTimeout(() => {
                                    setAddLoad(false)
                                }, 1900)
                            } else {
                                setIsStok(!isStok)
                            }
                        }}>SEPETE EKLE</Button>
                    </div>
                </div> : null}

                <div className={addLoad ? 'animationimg' : 'notimg'}>
                    {data.Urun_Resimleri !== undefined ? data.Urun_Resimleri.length !== 0 ? <div className='arti1'><img src={data.Urun_Resimleri.length !== 0 ? data.Urun_Resimleri[0].kucukresim : logoImg} height={40} width={40} style={{ borderRadius: 20 }} alt='' /></div> : null : null}
                </div>

                {data.Urun_Bilgileri !== undefined ? <div className='cargo'>
                    <img src={kargo} />
                    <span>{data.Urun_Bilgileri[0].Urun_TedarikSuresi}</span>
                </div> : null}

                {data.Urun_Bilgileri !== undefined ? <div className='properties mt-4'>
                    <div className='border border-secondary p-2'><span>ÜRÜN ÖZELLİKLERİ</span></div>
                </div> : null}

                {data.Urun_Bilgileri !== undefined ? <div className='m-3 pb-5 text-secondary' dangerouslySetInnerHTML={{ __html: data.Urun_Bilgileri[0].Urun_Aciklama }}></div> : null}
            </motion.div>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div><img src={langImg} alt='' width='100%' /></div>
            </Modal>

            <Modal isOpen={notProduct} toggle={() => setNotProduct(!notProduct)} className='modal-dialog-centered'>
                <div className='p-2'>
                    <div><b>TECH-IN</b></div>
                    <div><small>Üründen yeterli sayıda ürün yok! Stok Adedi: {data.Urun_Bilgileri !== undefined ? data.Urun_Bilgileri[0].Urun_StokMiktari : 0}</small></div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={isStok} toggle={() => setIsStok(!isStok)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h6>TECH-IN</h6></div>
                    <div>Ürün stoğu bulunmamaktadır. Lütfen daha sonra tekrar deneyiniz.</div>
                </div>
            </Modal>

            <BottomBar basket={isNewBasket ? basketLength : basketLengthh} />
        </motion.div>
    )
}

export default ProductDetail