import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import '../../css/style.css'
import { addressSaoVariant, basketContentVariant, mobilVariant, priceInfoVariant } from '../../components/animation'
import axios from 'axios'
import { bankagetir, kargoListesiKapidaOdemeli, krediKartlari, odemeTutarlari, siparisTamamlaCari, siparisTamamlaHavale, siparisTamamlaKKarti, siparisTamamlaKOdemeli, taksitleriGetir } from '../../api/api'
import BottomBar from '../../components/bottombar'
import { Card, Form, Input, Button, Modal } from 'reactstrap'
import { encode, expriyFormat } from '../../components/general'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import MenuHeader from '../../components/menuHeader'
import { XCircle } from 'react-feather'

const animatedComponents = makeAnimated();

const PayInfo = ({ basketLength }) => {
    const [modal, setModal] = useState(false)
    const [selectPay, setSelectPay] = useState(1)
    const [number, SetNumber] = useState("")
    const [name, SetName] = useState("")
    const [date, SetDate] = useState("")
    const [isTaksit, setIsTaksit] = useState(false)
    const [cvc, SetCvc] = useState("")
    const [havaleMessage, setHavaleMessage] = useState("")
    const [havaleModal, setHavaleModal] = useState(false)
    const [kOdemeMessage, setKOdemeMessage] = useState("")
    const [kOdemeModal, setKOdemeModal] = useState(false)
    const [cariMessage, setCariMessage] = useState("")
    const [cariModal, setCariModal] = useState(false)
    const [first, setFirst] = useState(false)
    const [second, setSecond] = useState(false)
    const [third, setThird] = useState(false)
    const [fourth, setFourth] = useState(false)
    const [data, setData] = useState([])
    const [banks, setBanks] = useState([])
    const [taksitOk, setTaksitOk] = useState(false)
    const [havaleOk, setHavaleOk] = useState(false)
    const [cargoData, setCargoData] = useState([])
    const [cariOk, setCariOk] = useState(false)
    const [jokerVadaa, setJokerVadaa] = useState(false)
    const [selectbank, setSelectbank] = useState(0)
    const [selectCargo, setSelectCargo] = useState(0)
    const tesAdres = JSON.parse(localStorage.getItem('tesAdres'))
    const fatAdres = JSON.parse(localStorage.getItem('fatAdres'))
    const cargo = JSON.parse(localStorage.getItem('cargo'))
    const [currentTaksitStatus, setCurrentTaksitStatus] = useState({ number: 0, value: 0, label: 'Taksit Seçiniz', taksit: 0 })
    const [dataTaksitOptions, setDataTaksitOptions] = useState({ number: 0, value: 0, label: 'Taksit Seçiniz', taksit: 0 })
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [currentStatus, setCurrentStatus] = useState({ number: 0, value: 0, label: 'Banka Seçiniz' })
    const [dataOptions, setDataOptions] = useState({ number: 0, value: 0, label: 'Banka Seçiniz' })
    const [selectedBank, setSelectedBank] = useState('MA==')

    const getData2 = () => {
        axios.get(krediKartlari).then(res => {
            if (res.status === 200) {
                const valueList = []
                const value = res.data
                for (let i = 0; i < value.length; i++) {
                    valueList.push({ value: value[i].KrediKarti_Id, label: value[i].KrediKarti_BankaAdi })
                }
                setDataOptions(valueList)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const getTaksit = (datas) => {
        setJokerVadaa(false)
        if (number.length < 20 && number.length > 15) {
            axios.get(`${taksitleriGetir}${datas}`).then(res => {
                const valueList = []
                const value = res.data
                console.log(value)
                if (value !== null && value !== undefined) {
                    for (let i = 0; i < value.length; i++) {
                        valueList.push({ value: value[i].Taksit_Id, label: value[i].Taksit_Baslik, number: value[i].Taksit_KrediKarti, taksit: value[i].Taksit_Sayi })
                    }
                }
                setDataTaksitOptions(valueList)
            }).catch(er => {
                setErrorModal(!errorModal)
                setErrorMessage(er.message)
            })
        }
    }

    const getData = () => {
        if (localStorage.getItem('tesAdres') !== undefined && localStorage.getItem('cargo') !== undefined) {
            axios.get(`${odemeTutarlari}${window.btoa(tesAdres.value)},${window.btoa(cargo.value)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
                setData(res.data[0])
            }).catch(er => {
                setErrorModal(!errorModal)
                setErrorMessage(er.message)
            })
        }

        axios.get(`${kargoListesiKapidaOdemeli}`).then(res => {
            setCargoData(res.data)
            setSelectCargo(res.data[0].Kargo_Id)
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })

        axios.get(bankagetir).then(res => {
            if (res.status === 200) {
                setBanks(res.data)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const makeHavale = () => {
        axios.get(`${siparisTamamlaHavale}${window.btoa(localStorage.getItem('auth'))},${window.btoa(tesAdres.value)},${window.btoa(fatAdres.value)},${window.btoa(cargo.value)},${window.btoa(selectbank)},${window.btoa(localStorage.getItem('musteriNotu'))}`).then(res => {
            const value = res.data[0]
            setHavaleMessage(value.sonuc)
            setHavaleModal(true)
            if (value.durum === "1") {
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000)
            } else {
                setTimeout(() => {
                    setHavaleModal(false)
                    setSelectPay(1)
                }, 1000)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const makeKOdeme = () => {
        axios.get(`${siparisTamamlaKOdemeli}${window.btoa(localStorage.getItem('auth'))},${window.btoa(tesAdres.value)},${window.btoa(fatAdres.value)},${window.btoa(selectCargo)},${window.btoa(localStorage.getItem('musteriNotu'))}`).then(res => {
            const value = res.data[0]
            setKOdemeMessage(value.sonuc)
            setKOdemeModal(true)
            if (value.durum === "1") {
                setTimeout(() => {
                    window.location.href = '/'
                }, 2000)
            } else {
                setTimeout(() => {
                    setKOdemeModal(false)
                }, 2000)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const makeCari = () => {
        axios.get(`${siparisTamamlaCari}${window.btoa(localStorage.getItem('auth'))},${window.btoa(tesAdres.value)},${window.btoa(fatAdres.value)},${window.btoa(cargo.value)},${window.btoa('1')},${window.btoa(localStorage.getItem('musteriNotu'))}`).then(res => {
            const value = res.data[0]
            setCariMessage(value.sonuc)
            setCariModal(true)
            if (value.durum === "1") {
                setTimeout(() => {
                    window.location.href = '/orders'
                }, 3000)
            } else {
                setTimeout(() => {
                    setCariModal(false)
                }, 3000)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const makePay = () => {
        axios.get(`${siparisTamamlaKKarti}${window.btoa(localStorage.getItem('auth'))},${window.btoa(tesAdres.value)},${window.btoa(fatAdres.value)},${window.btoa(cargo.value)},${window.btoa(currentTaksitStatus.number)},${window.btoa(currentTaksitStatus.taksit)},${window.btoa(name)},${window.btoa(localStorage.getItem('musteriNotu'))},${isTaksit ? 'MA==' : 'MQ=='},${jokerVadaa ? 'MQ==' : 'MA=='}`).then(res => {
            const value = JSON.stringify(res.data[0].form)
            const skt = date.split('/')
            const mp = number.split(' ')
            let newHtml = ''

            newHtml = value.replace("name='kartsahibi' value=''", `name='kartsahibi' value='${name}'`).replace("name='pan' value=''", `name='pan' value='${number.replace(/\s/g, '')}'`).replace("name='Ecom_Payment_Card_ExpDate_Month' value=''", `name='Ecom_Payment_Card_ExpDate_Month' value='${skt[0]}'`).replace("name='Ecom_Payment_Card_ExpDate_Year' value=''", `name='Ecom_Payment_Card_ExpDate_Year' value='${skt[1]}'`).replace("name='cv2' value=''", `name='cv2' value='${cvc}'`).replace("name='mp' value=''", `name='mp' value='${mp[3]}'`).replace("name='kartsahibi' value=''", `name='kartsahibi' value='${name}'`)

            const pageContentUrl = encode(JSON.parse(newHtml))
            localStorage.setItem('treeDUrl', pageContentUrl)

            window.location.href = '/treed'
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    const onChangeExp = (e) => {
        SetDate(e.target.value);
        if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setThird(true) } else { setThird(false) }
    };

    useEffect(() => { getData(); getData2() }, [])

    return (
        <motion.div {...mobilVariant} className='pay-info'>
            <MenuHeader title={'ÖDEME BİLGİLERİ'} basket={basketLength} isSearch={false} />

            <motion.div {...addressSaoVariant} className='sao'>
                <div className='section'><span>Sepetim</span></div>
                <div className='line'></div>
                <div className='section'><span>Adres Bilgileri</span></div>
                <div className='line'></div>
                <div className='section select'><span>Ödeme Bilgileri</span></div>
            </motion.div>

            <motion.div {...priceInfoVariant} className='info'>
                <Card className='p-2 ml-4 mr-4 mt-4 pl-3 pr-3'>
                    <div className='W-100'>
                        <div className='item'>
                            <div><span>ARA TOPLAM</span></div>
                            <div className='text-right'><small>........................</small></div>
                            <div className='text-right'><span>{data.AraToplam} ₺</span></div>
                        </div>
                        <div className='item'>
                            <div><span>KARGO</span></div>
                            <div className='text-right'><small>........................</small></div>
                            <div className='text-right'><span>{data.KargoFiyati} ₺</span></div>
                        </div>
                        <div className='item'>
                            <div><span>KDV</span></div>
                            <div className='text-right'><small>........................</small></div>
                            <div className='text-right'><span>{data.KdvToplam} ₺</span></div>
                        </div>
                        <div className='item'>
                            <div><span>TOPLAM</span></div>
                            <div className='text-right'><small>........................</small></div>
                            <div className='text-right'><span>{data.GenelToplam} ₺</span></div>
                        </div>
                    </div>
                </Card>
            </motion.div>

            <motion.div {...priceInfoVariant} className='odeme-seklis'>
                <div onClick={() => setSelectPay(1)} className={selectPay === 1 ? 'active' : ''}><span>Kredi Kartı</span></div>
                <div onClick={() => setSelectPay(2)} className={selectPay === 2 ? 'active' : 'border-left-0 border-right-1'}><span>Havale/EFT</span></div>
                <div onClick={() => setSelectPay(4)} className={selectPay === 4 ? 'active' : 'border-left-0 border-right-0'}><span>Kapıda Ödeme</span></div>
                <div onClick={() => setSelectPay(3)} className={selectPay === 3 ? 'active' : ''}><span>Cari Ödeme</span></div>
            </motion.div>

            {
                selectPay === 1 ? <motion.div {...basketContentVariant} className='view ml-4 mr-4'>
                    <Form>
                        <div className='mb-2'>
                            <Input
                                placeholder='Kartın Üzerindeki İsim'
                                type='input'
                                value={name}
                                onChange={e => {
                                    SetName(e.target.value)
                                    if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setFirst(true) } else { setFirst(false) }
                                }}
                            />
                        </div>
                        <div className='mb-2'>
                            <Input
                                type="tel"
                                placeholder='Kart Numarası'
                                className='mb-2'
                                name="number"
                                value={number}
                                onChange={e => {
                                    if (e.target.value.length < 20) {
                                        e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                                        SetNumber(e.target.value)
                                    }
                                    if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setSecond(true) } else { setSecond(false) }
                                }}
                            />
                        </div>
                        <div className='mb-2'>
                            <Input
                                placeholder='Son Kullanma Tarihi'
                                type="tel"
                                name="month"
                                maxLength={5}
                                onChange={onChangeExp}
                                value={expriyFormat(date)}
                            />
                        </div>
                        <div className='mb-2'>
                            <Input
                                placeholder='Güvenlik Kodu'
                                type="tel"
                                name="cvc"
                                value={cvc}
                                onChange={e => {
                                    if (e.target.value.length < 4) {
                                        SetCvc(e.target.value)
                                    } else {
                                        SetCvc(e.target.value.slice(0, 3))
                                    }
                                    if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setFourth(true) } else { setFourth(false) }
                                }}
                            />
                        </div>

                        <div className='select-bank mt-3'>
                            <Select
                                noOptionsMessage={() => 'Banka Bulunamadı!'}
                                isClearable={false}
                                className="react-select"
                                classNamePrefix="select"
                                options={dataOptions}
                                isDisabled={first === true && second === true && third === true && fourth === true ? false : true}
                                components={animatedComponents}
                                value={currentStatus}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: 13,
                                    }),
                                }}
                                onChange={data => {
                                    setCurrentStatus(data);
                                    setSelectedBank(window.btoa(data.value))
                                    getTaksit(window.btoa(data.value))
                                }}
                            />
                        </div>

                        <div className='odeme-sekli'>
                            <div><span>TEK ÇEKİM</span></div>
                            <div>
                                <label className="switch">
                                    <Input
                                        type="checkbox"
                                        disabled={first === true && second === true && third === true && fourth === true ? false : true}
                                        onChange={e => setIsTaksit(e.target.checked)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div><span className='text-secondary'>TAKSİTLİ ÇEKİM</span></div>
                        </div>

                        {isTaksit ? <div className='select-bank mt-3'>
                            <Select
                                noOptionsMessage={() => 'Taksit bulunamadı!'}
                                isClearable={false}
                                className="react-select"
                                classNamePrefix="select"
                                options={dataTaksitOptions}
                                isDisabled={first === true && second === true && third === true && fourth === true ? false : true}
                                components={animatedComponents}
                                value={currentTaksitStatus}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: 13,
                                    }),
                                }}
                                onChange={data => {
                                    setCurrentTaksitStatus(data);
                                }}
                            />
                        </div> : null}

                        {/* <div className='joker'>
                            <Input type='checkbox' onChange={e => setJokerVadaa(e.target.checked)} /><span>JOKER VADAA Kullan!</span>
                        </div> */}

                        <div className='satissozlesmesi'>
                            <Input type='checkbox' onChange={e => setTaksitOk(e.target.checked)} /><span><a href='/contracts/11' className='text-dark'><b>Satış Sözleşmesi</b></a>'ni okudum ve kabul ediyorum.</span>
                        </div>

                        <div className='tamamla'>
                            <Button onClick={() => {
                                if (!taksitOk) {
                                    setModal(!modal)
                                } else {
                                    makePay()
                                }
                            }} disabled={first === true && second === true && third === true && fourth === true ? false : true}>SİPARİŞİ TAMAMLA</Button>
                        </div>
                    </Form>
                </motion.div> : null
            }

            {
                selectPay === 2 ? <motion.div {...basketContentVariant} className='havale'>
                    {
                        banks.map((item, index) => {
                            return (
                                <Card key={index} className={item.BankaHesap_Id === selectbank ? 'active shadow' : ''} onClick={() => setSelectbank(item.BankaHesap_Id)}>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div className={item.BankaHesap_Id === selectbank ? 'selected' : 'select'}></div>
                                        <div>
                                            <div><b>{item.BankaHesap_Banka}</b></div>
                                            <div><span>{item.BankaHesap_Alici}</span></div>
                                            <div><span>IBAN: {item.BankaHesap_IBan}</span></div>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })
                    }
                    <div className='satissozlesmesi'>
                        <Input type='checkbox' onChange={e => setHavaleOk(e.target.checked)} /><span><a href='/contracts/11' className='text-dark'><b>Satış Sözleşmesi</b></a>'ni okudum ve kabul ediyorum.</span>
                    </div>
                    <div className='tamamla'>
                        <Button onClick={() => !havaleOk ? setModal(!modal) : makeHavale()}>SİPARİŞİ TAMAMLA</Button>
                    </div>
                </motion.div> : null
            }

            {
                selectPay === 3 ? <motion.div {...basketContentVariant} className='cari-borc'>
                    <div>
                        <Card className='shadow'>
                            <div className='d-flex justify-content-start'>
                                <div className='select'></div>
                                <div><span>Cari Borç Kaydedilsin</span></div>
                            </div>
                        </Card>

                        <div className='satissozlesmesi ml-5 mt-4'>
                            <Input type='checkbox' onChange={e => setCariOk(e.target.checked)} /><span><a href='/contracts/11' className='text-dark'><b>Satış Sözleşmesi</b></a>'ni okudum ve kabul ediyorum.</span>
                        </div>

                        <div className='tamamla'>
                            <Button onClick={() => !cariOk ? setModal(!modal) : makeCari()}>SİPARİŞİ TAMAMLA</Button>
                        </div>
                    </div>
                </motion.div> : null
            }

            {
                selectPay === 4 ? <motion.div  {...basketContentVariant} className='cari-borc'>
                    {
                        cargoData.map((item, index) => {
                            return (
                                <Card key={index} className={item.Kargo_Id === selectCargo ? 'active shadow' : ''} onClick={() => setSelectCargo(item.Kargo_Id)}>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div className={item.Kargo_Id === selectCargo ? 'selected' : 'select'}></div>
                                        <div>
                                            <div><b style={{ fontWeight: 900 }}>{item.Kargo_Baslik}</b></div>
                                            <div><span>Kapıda Ödeme Ücreti: {item.Kargo_KapidaOdemeTutari},00 ₺</span></div>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })
                    }

                    <div className='satissozlesmesi ml-5 mt-4'>
                        <Input type='checkbox' onChange={e => setCariOk(e.target.checked)} /><span><a href='/contracts/11' className='text-dark'><b>Satış Sözleşmesi</b></a>'ni okudum ve kabul ediyorum.</span>
                    </div>

                    <div className='tamamla'>
                        <Button onClick={() => !cariOk ? setModal(!modal) : makeKOdeme()}>SİPARİŞİ TAMAMLA</Button>
                    </div>
                </motion.div> : null
            }

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div className='text-danger'><b>Satış Sözleşmesi</b></div>
                    <div className='mt-2'><span>Satış sözleşmesini okuyup kabul ediniz!</span></div>
                </div>
            </Modal>

            <Modal isOpen={havaleModal} toggle={() => setHavaleModal(!havaleModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><b>TECH-IN</b></div>
                    <div><small>{havaleMessage}</small></div>
                </div>
            </Modal>

            <Modal isOpen={kOdemeModal} toggle={() => setKOdemeModal(!kOdemeModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><b>TECH-IN</b></div>
                    <div><small>{kOdemeMessage}</small></div>
                </div>
            </Modal>

            <Modal isOpen={cariModal} toggle={() => setCariModal(!cariModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><b>TECH-IN</b></div>
                    <div><small>{cariMessage}</small></div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default PayInfo