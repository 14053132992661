import React, { useEffect, useState } from 'react'
import { formVariant, mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import BottomBar from '../../components/bottombar'
import { Input, Form, Button, Modal } from 'reactstrap'
import { encode, expriyFormat } from '../../components/general'
import axios from 'axios'
import { krediKartlari, siparistamamlaozel, taksitleriGetir } from '../../api/api'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import MenuHeader from '../../components/menuHeader'
import { XCircle } from 'react-feather'

const animatedComponents = makeAnimated();

const Pay = ({ basketLength }) => {
    const [number, SetNumber] = useState('')
    const [name, setName] = useState('')
    const [date, SetDate] = useState('')
    const [cvc, SetCvc] = useState('')
    const [currentStatus, setCurrentStatus] = useState({ number: 0, value: 0, label: 'Banka Seçiniz' })
    const [currentTaksitStatus, setCurrentTaksitStatus] = useState({ number: 0, value: 0, label: 'Taksit Seçiniz' })
    const [selectedBank, setSelectedBank] = useState('MA==')
    const [selectedTaksit, setSelectedTaksit] = useState('MA==')
    const [price, setPrice] = useState('')
    const [priceK, setPriceK] = useState('')
    const [isTaksit, setIsTaksit] = useState(false)
    const [first, setFirst] = useState(false)
    const [second, setSecond] = useState(false)
    const [third, setThird] = useState(false)
    const [fourth, setFourth] = useState(false)
    const [priceNote, setPriceNote] = useState('')
    const [notModal, setNotModal] = useState(false)
    const [dataOptions, setDataOptions] = useState({ number: 0, value: 0, label: 'Banka Seçiniz' })
    const [dataTaksitOptions, setDataTaksitOptions] = useState({ number: 0, value: 0, label: 'Taksit Seçiniz' })
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const { handleSubmit } = useForm()

    const onChangeExp = (e) => {
        SetDate(e.target.value);
        if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setThird(true) } else { setThird(false) }
    };

    const getTaksit = (datas) => {
        if (number.length < 20 && number.length > 15) {
            axios.get(`${taksitleriGetir}${datas}`).then(res => {
                const valueList = []
                const value = res.data
                console.log(value)
                if (value !== null && value !== undefined) {
                    for (let i = 0; i < value.length; i++) {
                        valueList.push({ value: value[i].Taksit_Id, label: value[i].Taksit_Baslik, number: value[i].Taksit_KrediKarti })
                    }
                }
                setDataTaksitOptions(valueList)
            }).catch(er => {
                setErrorModal(!errorModal)
                setErrorMessage(er.message)
            })
        }
    }

    const getData = () => {
        axios.get(krediKartlari).then(res => {
            if (res.status === 200) {
                const valueList = []
                const value = res.data
                console.log(value)
                for (let i = 0; i < value.length; i++) {
                    valueList.push({ value: value[i].KrediKarti_Id, label: value[i].KrediKarti_BankaAdi })
                }
                setDataOptions(valueList)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    // serdar erdoğan
    // 5269110255111553
    // 1227
    // 460

    const onSubmit = (values, e) => {
        const newPrice = `${price},${priceK === '' ? '00' : priceK}`

        axios.get(`${siparistamamlaozel}${window.btoa(localStorage.getItem('auth'))},${selectedBank},${selectedTaksit},${encode(name)},${isTaksit ? 'MQ==' : 'MA=='},${window.btoa(newPrice)},${encode(priceNote !== '' ? priceNote : 'deneme')},MA==`).then(res => {
            console.log(res.data)
            if (res.data[0].ozelOdemeId !== null && res.data[0].ozelOdemeId !== undefined) {
                const value = JSON.stringify(res.data[0].form)
                const skt = date.split('/')
                const mp = number.split(' ')
                let newHtml = ''

                newHtml = value.replace("name='kartsahibi' value=''", `name='kartsahibi' value='${name}'`).replace("name='pan' value=''", `name='pan' value='${number.replace(/\s/g, '')}'`).replace("name='Ecom_Payment_Card_ExpDate_Month' value=''", `name='Ecom_Payment_Card_ExpDate_Month' value='${skt[0]}'`).replace("name='Ecom_Payment_Card_ExpDate_Year' value=''", `name='Ecom_Payment_Card_ExpDate_Year' value='${skt[1]}'`).replace("name='cv2' value=''", `name='cv2' value='${cvc}'`).replace("name='mp' value=''", `name='mp' value='${mp[3]}'`).replace("name='kartsahibi' value=''", `name='kartsahibi' value='${name}'`)

                const pageContentUrl = encode(JSON.parse(newHtml))

                localStorage.setItem('treeDUrl', pageContentUrl)

                window.location.href = `/treed`

            } else {
                setNotModal(true)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <motion.div {...mobilVariant} className='pay'>
            <MenuHeader title={'ÖDEME YAP'} basket={basketLength} isSearch={false} />

            <motion.div className='odeme' {...formVariant}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        type='input'
                        placeholder='Kart Sahibi Ad Soyad'
                        className='mb-2'
                        onChange={e => {
                            setName(e.target.value)
                            if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setFirst(true) } else { setFirst(false) }
                        }} />
                    <Input
                        type="tel"
                        placeholder='Kart Numarası'
                        className='mb-2'
                        name="number"
                        value={number}
                        onChange={e => {
                            if (e.target.value.length < 20) {
                                e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                                SetNumber(e.target.value)
                            }
                            if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setSecond(true) } else { setSecond(false) }
                        }}
                    />
                    <Input
                        type="tel"
                        name="month"
                        placeholder="Ay/Yıl"
                        maxLength={5}
                        onChange={onChangeExp}
                        className='mb-2'
                        value={expriyFormat(date)}
                    />
                    <Input
                        placeholder='CVC'
                        type="tel"
                        name="cvc"
                        value={cvc}
                        className='mb-2'
                        onChange={e => {
                            if (e.target.value.length < 4) {
                                SetCvc(e.target.value)
                            } else {
                                SetCvc(e.target.value.slice(0, 3))
                            }
                            if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setFourth(true) } else { setFourth(false) }
                        }}
                    />
                    <Input type='textarea' style={{ fontSize: 13 }} placeholder='Ödeme Notu' onChange={e => setPriceNote(e.target.value)} />
                    <div className='odeme-tutar'>
                        <div><span style={{ fontSize: 15 }}>Ödeme Tutarı</span></div>
                        <div className='ana'><Input type='number' onChange={e => setPrice(e.target.value)} /></div>
                        <div><span>,</span></div>
                        <div className='kusur'><Input type='number' onChange={e => setPriceK(e.target.value)} /></div>
                        <div><small style={{ fontSize: 10, color: 'grey' }}>₺ - Örn: (100,00)</small></div>
                    </div>

                    <div className='select-bank mt-3'>
                        <Select
                            noOptionsMessage={() => 'Banka Bulunamadı!'}
                            isClearable={false}
                            className="react-select"
                            classNamePrefix="select"
                            options={dataOptions}
                            isDisabled={first === true && second === true && third === true && fourth === true ? false : true}
                            components={animatedComponents}
                            value={currentStatus}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontSize: 13,
                                }),
                            }}
                            onChange={data => {
                                setCurrentStatus(data);
                                setSelectedBank(window.btoa(data.value))
                                getTaksit(window.btoa(data.value))
                            }}
                        />
                    </div>

                    <div className='odeme-sekli'>
                        <div><span>TEK ÇEKİM</span></div>
                        <div>
                            <label className="switch">
                                <Input
                                    type="checkbox"
                                    disabled={first === true && second === true && third === true && fourth === true ? false : true}
                                    onChange={e => setIsTaksit(e.target.checked)}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div><span color='#242d41'>TAKSİTLİ ÇEKİM</span></div>
                    </div>



                    {isTaksit ? <div className='select-bank mt-3'>
                        <Select
                            noOptionsMessage={() => 'Taksit bulunamadı!'}
                            isClearable={false}
                            className="react-select"
                            classNamePrefix="select"
                            options={dataTaksitOptions}
                            isDisabled={first === true && second === true && third === true && fourth === true ? false : true}
                            components={animatedComponents}
                            value={currentTaksitStatus}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontSize: 13,
                                }),
                            }}
                            onChange={data => {
                                setCurrentTaksitStatus(data);
                                setSelectedTaksit(window.btoa(data.value))
                            }}
                        />
                    </div> : null}

                    <div className='odeme-tamamla'>
                        <Button disabled={first === true && second === true && third === true && fourth === true ? false : true}
                            components={animatedComponents}>ÖDEMEYİ TAMAMLA</Button>
                    </div>
                </Form>
            </motion.div>

            <Modal isOpen={notModal} toggle={() => setNotModal(!notModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h5>Asil Grup</h5></div>
                    <div><small>Ödeme işleminde sorun oluştu lütfen tekrar deneyiniz.</small></div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default Pay