export const expriyFormat = (value) => {
    const expdate = value;
    const expDateFormatter =
        expdate.replace(/\//g, "").substring(0, 2) +
        (expdate.length > 2 ? "/" : "") +
        expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
}

export const toUtf8 = (text) => {
    var surrogate = encodeURIComponent(text);
    var result = '';
    for (var i = 0; i < surrogate.length;) {
        var character = surrogate[i];
        i += 1;
        if (character == '%') {
            var hex = surrogate.substring(i, i += 2);
            if (hex) {
                result += String.fromCharCode(parseInt(hex, 16));
            }
        } else {
            result += character;
        }
    }
    return result;
};


export const encode = (input) => {
    const _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;
    input = _utf8_encode(input);
    while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output = output +
            _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
    }
    return output;
}

const _utf8_encode = (string) => {
    string = string.replace(/\r\n/g, "\n");
    var utftext = "";
    for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
            utftext += String.fromCharCode(c);
        }
        else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        }
        else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }
    }
    return utftext;
}

export const paraFormat = (deger) => {
    if (deger == null) { deger = 0; }
    deger = deger.toString().replace(",", ".");
    deger = parseFloat(deger);
    var re = '\\d(?=(\\d{' + (3 || 3) + '})+' + (2 > 0 ? '\\D' : '$') + ')',
        num = deger.toFixed(Math.max(0, ~~2));
    return (',' ? num.replace('.', ',') : num).replace(new RegExp(re, 'g'), '$&' + ('.' || ','));
}

export const convertTr = (text) => {

    var Maps = {
        "G&#252;m&#252;ş": "Gumus", "&#220;": "Ü", "&#252;": "ü", "İ": "I", "Ş": "S", "Ç": "C", "Ğ": "G", "Ü": "U", "Ö": "O",
        "ı": "i", "ş": "s", "ç": "c", "ğ": "g", "ü": "u", "ö": "o", "&#231;": "ç"
    };

    Object.keys(Maps).forEach(function (Old) {
        text = text.replace(Old, Maps[Old]);
    });
    return text;
}
