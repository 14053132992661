export const url = 'https://ws.teknobay.com.tr/TTMobile/'
export const webURL = "https://www.tekinteknoloji.com.tr/"
export const brandUrl = `${webURL}Webkontrol/MarkaYonetimi/GaleriResim/BuyukResim/`
export const sayfalar = `${url}sayfalar/MA==`
export const giris = `${url}uyeGirisi/`
export const sifremiunuttum = `${url}uyeSifremiUnuttum/`
export const kayitol = `${url}uyeKayitOl/`
export const slider = `${url}slider/`
export const vitrin = `${url}vitrinKategoriListeleme/Mw==`
export const vitrinUrun = `${url}vitrinUrunListeleme/NQ==,`
export const bakiye = `${url}bakiyeBilgisi/`
export const puanbilgisi = `${url}puanBilgisi/`
export const urunlisteleme = `${url}urunListeleme/`
export const urundetay = `${url}urunDetay/`
export const sepetigetir = `${url}sepetiGetir/`
export const bankagetir = `${url}bankaHesap`
export const siparistamamlaozel = `${url}siparisTamamlaOzel/`
export const ozelOdemeTaksitli = `${url}ozelOdemeTaksitli/`
export const aramayap = `${url}AramaYap/`
export const uyebilgileri = `${url}uyeProfilBilgileri/`
export const siparisgecmisi = `${url}siparisGecmisi/`
export const siparisdetay = `${url}siparisDetayGetir/`
export const musteriadresler = `${url}musteriAdresListesi/`
export const musteriadressil = `${url}musteriAdresiSil/`
export const adresigetir = `${url}musteriAdresiGetir/`
export const adresduzenle = `${url}musteriAdresDuzenle/`
export const musteriadresaydet = `${url}musteriAdresKaydet/`
export const uyeProfilBilgileriGuncelle = `${url}uyeProfilBilgileriGuncelle/`
export const odemeGecmisi = `${url}odemeGecmisi/`
export const sepeteekle = `${url}sepeteEkle/`
export const sepettensil = `${url}sepettenSil/`
export const sepetiBosalt = `${url}sepetiBosalt/`
export const sepetAdetGuncelle = `${url}sepetAdetGuncelle/`
export const odemeTutarlariHavale = `${url}odemeTutarlariHavale/`
export const musteriAdreslerineGoreKargoFiyati = `${url}musteriAdreslerineGoreKargoFiyati/`
export const urunKategorileriSolMenu = `${url}_urunKategorileriSolMenu/MA==`
export const siparisTamamlaKKarti = `${url}siparisTamamlaKKarti/`
export const odemeTutarlari = `${url}odemeTutarlari/`
export const taksitleriGetir = `${url}taksitleriGetir/`
export const kargoListesiKapidaOdemeli = `${url}kargoListesiKapidaOdemeli/`
export const vegaCariSorgula = `${url}VegaCariSorgula/`
export const vegaCariSorgula2 = `${url}VegaCariSorgula2/`
export const siparisTamamlaHavale = `${url}siparisTamamlaHavale/`
export const siparisTamamlaKOdemeli = `${url}siparisTamamlaKOdemeli/`
export const siparisTamamlaCari = `${url}siparisTamamlaCari/`
export const krediKartlari = `${url}krediKartlari`
export const districtList = `${url}sehirIlceGetir/`
export const uyeSilme = `${url}uyeSilme/`
export const markalarGetir = `${url}markalarGetir/`
export const markaUrunListeleme = `${url}markaUrunListeleme/`