import React, { useState } from 'react'
import home from '../img/home.png'
import product from '../img/product.png'
import shopping from '../img/shopping.png'
import brands from '../img/brands.png'
import account from '../img/account.png'
import logo from '../img/logo.png'
import { motion } from 'framer-motion'
import { lefMenuMotion } from '../components/animation'
import profil from '../img/profil.png'
import { ChevronRight, XCircle } from 'react-feather'
import { useEffect } from 'react'
import axios from 'axios'
import { urunKategorileriSolMenu, uyebilgileri, vegaCariSorgula2 } from '../api/api'
import { Modal } from 'reactstrap'
import { convertTr, paraFormat } from './general'

const BottomBar = ({ basket }) => {
    const [canvasOpen, setCanvasOpen] = useState(false)
    const isLogin = localStorage.getItem('isLogin')
    const [initDate, setInitDate] = useState('2000-01-01')
    const [leftMenu, setLeftMenu] = useState([])
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [data2, setData2] = useState([])
    const [openIndex, setOpenIndex] = useState({ baslik1: "", baslik2: "", baslik3: "", baslik4: "", baslik5: "" })
    const [bakiye, setBakiye] = useState("")
    var now = Date.now();
    var utc = new Intl.DateTimeFormat('tr-TR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(now).replace(".", "-").replace(".", "-").split("-")
    var days = `${utc[2]}-${utc[1]}-${utc[0]}`

    const getBakiye = (kod, dt) => {
        axios.get(`${vegaCariSorgula2}${kod}/${initDate}/${dt === null ? '2000-01-01' : dt}`).then(res => {
            var bakiye2 = 0;
            for (var i = 0; i < res.data.length; i++) {
                bakiye2 += parseFloat(res.data[i].KALANBAKIYE)
            }
            setBakiye(bakiye2)
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => {
        axios.get(urunKategorileriSolMenu).then(res => {
            if (res.data !== null && res.data !== undefined) {
                setLeftMenu(res.data)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })

        axios.get(`${uyebilgileri}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.data !== null && res.data !== undefined) {
                setData2(res.data[0])
                getBakiye(res.data[0].Musteri_BlKodu, days)
                // setPuan(res.data[0].Musteri_Puan)
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }, [])


    return (
        <div className='bottomBar bg-white w-100 d-flex justify-content-between w-100 pl-2 pr-2 pt-2 pb-1' style={{ position: 'fixed', bottom: 0, borderTop: '1px solid rgb(210, 210, 210)', zIndex: 999999 }}>
            <div className='align-items-center text-center' onClick={() => window.location.href = '/'}>
                <div><img src={home} alt='' height={25} /></div>
                <div><small>TECH-IN</small></div>
            </div>
            <div className='align-items-center text-center' onClick={() => {
                localStorage.setItem('drawer', !canvasOpen);
                setCanvasOpen(!canvasOpen);
            }}>
                <div><img src={product} alt='' height={25} /></div>
                <div><small>Ürünler</small></div>
            </div>
            <div className='align-items-center text-center position-relative' onClick={() => window.location.href = '/basket'}>
                <div><img src={shopping} alt='' height={25} /></div>
                <div className='position-absolute' style={{ backgroundColor: '#242d41', fontSize: 12, alignItems: 'center', textAlign: 'center', display: 'flex', justifyContent: 'center', width: 20, height: 20, borderRadius: 10, color: 'white', bottom: 30, left: 20 }}><span>{isLogin !== '0' ? basket : '0'}</span></div>
                <div><small>Sepet</small></div>
            </div>
            <div className='align-items-center text-center' onClick={() => window.location.href = '/pay'}>
                <div><img src={brands} alt='' height={25} /></div>
                <div><small>Ödeme Yap</small></div>
            </div>
            <div className='align-items-center text-center' onClick={() => window.location.href = '/account'}>
                <div><img src={account} alt='' height={25} /></div>
                <div><small>{isLogin === '0' ? 'Giriş' : 'Hesabım'}</small></div>
            </div>

            {/* burak@teknobay.com.tr */}
            {/* 987456321 */}

            {
                canvasOpen ? <div className='leftDrawerMenu'>
                    <div className='leftBack' onClick={() => {
                        setCanvasOpen(!canvasOpen);
                    }}></div>
                    <motion.div {...lefMenuMotion} className='leftDrawer'>
                        <div style={{ marginTop: 30 }}>
                            <img src={logo} alt='' style={{ width: '90%', marginLeft: '5%' }} />
                        </div>
                        <div onClick={() => window.location.href = '/account'} className='profilInfo shadow'>
                            <div className='profilImg'><img src={profil} alt='' /></div>
                            <div className='text-center text-secondary mt-2 border border-secondary rounded'><span style={{ fontSize: 16 }}>{`${data2 === undefined ? '' : data2.Musteri_Ad} ${data2 === undefined ? '' : data2.Musteri_Soyad}`}</span><ChevronRight color='grey' size={20} style={{ marginLeft: 10, marginTop: -4 }} /></div>
                            <div className='puan text-center'>
                                <div className='mt-2'><span>Bakiye: </span><span>{paraFormat(bakiye)} $</span></div>
                                {/* <div><span>Puan: </span><span>{puan},0 ₺</span></div> */}
                            </div>
                        </div>
                        <div className='urunler'>

                            <div className='text-left mb-2'><h5><b>ÜRÜNLER</b></h5></div>

                            {
                                leftMenu !== undefined && leftMenu !== null ? leftMenu.map((item, index) => {
                                    return (
                                        <div className='title1' key={index}>
                                            <div className='d-flex justify-content-start'>
                                                {item.altKategoriler.length !== 0 ? <div onClick={() => {
                                                    setOpenIndex({ baslik1: `${index}` })
                                                }}><ChevronRight size={20} color='grey' /></div> : <div style={{ marginLeft: 20 }}></div>}
                                                <div className='ml-1' onClick={() => window.location.href = `/product/${item.id}/${item.baslik}/0`}><span>{convertTr(item.baslik)}</span></div>
                                            </div>
                                            {
                                                openIndex.baslik1 === `${index}` ? item.altKategoriler.length !== 0 ? item.altKategoriler.map((item2, index2) => {
                                                    return (
                                                        <div key={index2}>
                                                            <div className='d-flex justify-content-start ml-3'>
                                                                {item2.altKategoriler.length !== 0 ? <div onClick={() => {
                                                                    setOpenIndex({ baslik1: `${index}`, baslik2: `${index2}` })
                                                                }}><ChevronRight size={20} color='grey' /></div> : <div style={{ marginLeft: 20 }}></div>}
                                                                <div className='ml-1' onClick={() => window.location.href = `/product/${item2.id}/${item2.baslik}/0`}><span className='text-secondary'>{convertTr(item2.baslik)}</span></div>
                                                            </div>
                                                            {
                                                                openIndex.baslik1 === `${index}` && openIndex.baslik2 === `${index2}` ? item2.altKategoriler.length !== 0 ? item2.altKategoriler.map((item3, index3) => {
                                                                    return (
                                                                        <div key={index3}>
                                                                            <div className='d-flex justify-content-start ml-3'>
                                                                                {item3.altKategoriler.length !== 0 ? <div><ChevronRight size={20} color='grey' /></div> : <div style={{ marginLeft: 20 }}></div>}
                                                                                <div className='ml-3' onClick={() => window.location.href = `/product/${item3.id}/${item3.baslik}/0`}><span className='text-secondary'>{convertTr(item3.baslik)}</span></div>
                                                                            </div>
                                                                            {
                                                                                openIndex.baslik1 === `${index}` && openIndex.baslik2 === `${index2}` && openIndex.baslik2 === `${index2}` ? item3.altKategoriler.length !== 0 ? item3.altKategoriler.map((item4, index4) => {
                                                                                    return (
                                                                                        <div key={index4}>
                                                                                            <div className='d-flex justify-content-start ml-3'>
                                                                                                {item4.altKategoriler.length !== 0 ? <div><ChevronRight size={20} color='grey' /></div> : <div style={{ marginLeft: 20 }}></div>}
                                                                                                <div className='ml-3' onClick={() => window.location.href = `/product/${item4.id}/${item4.baslik}/0`}><span className='text-secondary'>{convertTr(item4.baslik)}</span></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) : null : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                }) : null : null
                                                            }
                                                        </div>
                                                    )
                                                }) : null : null
                                            }
                                        </div>
                                    )
                                }) : null
                            }

                            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                                <div>
                                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                                        <div><h6>TECH-IN</h6></div>
                                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                                    </div>
                                    <div className='p-2'>
                                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                                    </div>
                                </div>
                            </Modal>

                        </div>
                    </motion.div>
                </div> : null
            }
        </div >
    )
}

export default BottomBar