import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { newBrandsVariant } from '../../components/animation'
import { Row, Col, Modal } from 'reactstrap'
import loadImg from '../../img/loading.svg'
import { vitrinUrun } from '../../api/api'
import axios from 'axios'
import { XCircle } from 'react-feather'

const NewBrands = () => {
    const [data, setData] = useState([])
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        axios.get(`${vitrinUrun}${window.btoa(localStorage.getItem('auth'))},MQ==,${window.btoa('1')},NTA=`).then(res => {
            if (res.data !== undefined && res.data !== null) {
                setData(res.data)
                setTimeout(() => {
                    setLoading(true)
                }, 1000);
            }
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }, [])
    
    return (
        loading ? <motion.div {...newBrandsVariant} className='newBrands'>
            <Row className='w-100 p-0 m-0'>
                {
                    data !== null && data !== undefined ? data.map((item, index) => {
                        return (
                            <Col sm={6} md={6} xl={6} key={index} className='w-50 text-center mb-3'>
                                <div style={{ border: '1px solid rgb(220, 220, 220)', borderRadius: 15, padding: 10, width: '100%' }} onClick={() => window.location.href = `/product-detail/${item.Urun_Id}`}>
                                    <div><img src={item.Medya_Ad} alt='' height={140} style={{ maxWidth: 150, zIndex: -1, position: 'relative' }} /></div>
                                    <div className='productName'><small>{item.Urun_Baslik}</small></div>
                                    {item.Medya_Renk !== null ? <div className='d-flex justify-content-center'>
                                        {
                                            item.Medya_Renk.split(',').map((i, j) => {
                                                if (j < 5) {
                                                    return (
                                                        <div style={{ width: 20, height: 20, borderRadius: 10, marginLeft: 2, marginRight: 2, border: '1px solid grey', alignItems: 'center', display: 'flex', justifyContent: 'center' }} key={j}>
                                                            <div style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: i }}></div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div> : <div className='d-flex justify-content-center'><div style={{ width: 20, height: 20, borderRadius: 10, marginLeft: 2, marginRight: 2, border: '1px solid grey', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: 'white' }}></div>
                                    </div></div>}
                                    <div><small style={{ fontSize: 11 }}>Ürün Kodu: {item.Urun_TicariYazilimId}</small></div>
                                    <div className='text-secondary'><del>{item.FiyatEtiket} {item.DovizTipi}</del></div>
                                    <div><span>{item.FiyatPerakende} {item.DovizTipi}</span></div>
                                </div>
                            </Col>
                        )
                    }) : null
                }
            </Row>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

        </motion.div> : <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <img src={loadImg} alt='' height={30} />
        </div>
    )
}

export default NewBrands