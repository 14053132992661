import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { mobilVariant, yandanVariant } from '../../components/animation'
import MenuHeader from '../../components/menuHeader'
import print from '../../img/print.png'
import prev from '../../img/prev.png'
import { Input, Modal } from 'reactstrap'
import BottomBar from '../../components/bottombar'
import axios from 'axios'
import { siparisgecmisi } from '../../api/api'
import { XCircle } from 'react-feather'

const Orders = ({ basketLength }) => {
    const [data, setData] = useState([])
    const [initDate, setInitDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getData = () => {
        axios.get(`${siparisgecmisi}${window.btoa(localStorage.getItem('auth'))},${window.btoa(initDate)},${window.btoa(endDate)}`).then(res => {
            setData(res.data)
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => { getData() }, [])
    
    return (
        <motion.div {...mobilVariant} className='orders'>
            <div className='noPrint'>
                <MenuHeader className="noPrint" title='SİPARİŞLERİM' basket={basketLength} isSearch={false} />
            </div>

            <div className='d-flex justify-content-around noPrint' style={{ marginTop: 80 }}>
                <div className='noPrint'>
                    <div className='d-flex justify-content-between noPrint'>
                        <div><Input type='date' onChange={e => setInitDate(e.target.value)} /></div>
                        <div className='ml-2'><Input type='date' onChange={e => setEndDate(e.target.value)} /></div>
                    </div>
                </div>
                <div onClick={() => window.print()} className='noPrint'>
                    <img src={print} alt='' height={30} />
                </div>
            </div>

            <div className='m-3 d-flex justify-content-between' style={{ fontSize: 13 }}>
                <div className='shadow p-2'>
                    SİPARİŞ NO
                </div>
                <div className='shadow p-2'>
                    SİPARİŞ TARİHİ
                </div>
                <div className='shadow p-2'>
                    TOPLAM TUTAR
                </div>
            </div>

            <motion.div {...yandanVariant}>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className='d-flex justify-content-around text-left border-bottom pt-1 pb-1' onClick={() => window.location.href = `/order-detail/${item.Siparis_Id}`}>
                                <div><span style={{ fontSize: 15, fontWeight: 'bold' }}>{item.Siparis_No}</span></div>
                                <div className=''><small>{new Date(item.Siparis_Tarih).toLocaleString()}</small></div>
                                <div><img src={prev} alt='' height={18} /></div>
                            </div>
                        )
                    })
                }
            </motion.div>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>

            <div className='noPrint'>
                <BottomBar basket={basketLength} />
            </div>
        </motion.div>
    )
}

export default Orders