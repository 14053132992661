import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { mobilVariant, productVariant } from '../../components/animation'
import MenuHeader from '../../components/menuHeader'
import BottomBar from '../../components/bottombar'
import { useParams } from 'react-router-dom'
import arrow from '../../img/arrow.png'
import axios from 'axios'
import { markaUrunListeleme, urunKategorileriSolMenu, urunlisteleme } from '../../api/api'
import { Button, Card, Col, Modal, Row } from 'reactstrap'
import loadImg from '../../img/loading.svg'
import { XCircle } from 'react-feather'

const Product = ({ basketLengthh }) => {
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [sorted, setSorted] = useState('0')
    const [data, setData] = useState([])
    const { id, name, isBrand } = useParams()
    const [errorModal, setErrorModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isCategory, setIsCategory] = useState(false)
    const [categories, setCategories] = useState([])
    const counter = useRef(0);

    const imageLoaded = () => {
        if (isLoad) {
            setTimeout(() => {
                setLoading(true)
            }, 1400)
        } else {
            counter.current += 1;
            if (counter.current >= 8) {
                setTimeout(() => {
                    setLoading(true)
                }, 1000)
            }
        }
    }

    const getData = (categoryId) => {
        // MTAwMA==
        setData([])
        console.log(categoryId)
        axios.get(`${isBrand === "0" ? urunlisteleme : markaUrunListeleme}${window.btoa(id)},${window.btoa(sorted)},${window.btoa(localStorage.getItem('auth'))}${categoryId == 0 ? '' : ',' + window.btoa(categoryId)}`).then(res => {
            setData(res.data)
            setIsLoad(true)
            setTimeout(() => {
                setLoading(true)
            }, 1000);
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })

        axios.get(`${urunKategorileriSolMenu}`).then(res => {
            setCategories(res.data)
        }).catch(er => {
            setErrorModal(!errorModal)
            setErrorMessage(er.message)
        })
    }

    useEffect(() => {
        getData(0)
    }, [])

    return (
        <motion.div {...mobilVariant} className='product' >
            <MenuHeader title={name} isSearch={false} basket={basketLengthh} />

            <div className='filter'>
                <div onClick={() => setOpenModal(!openModal)}>
                    <img src={arrow} alt='' />
                    <span>Sırala</span>
                </div>
                <div>
                    {data !== null ? <span>{data.length} ürün</span> : <span>0 ürün</span>}
                </div>
            </div>

            {loading ? <motion.div {...productVariant} className=''>
                <Row className='w-100 p-0 m-0'>
                    {
                        data !== null ? data.map((item, index) => {
                            return (
                                <Col sm={6} md={6} xl={6} key={index} className='w-50 text-center mb-3'>
                                    <img src={item.Medya} alt='' className='d-none' onLoad={imageLoaded} />
                                    <div style={{ border: '1px solid rgb(220, 220, 220)', borderRadius: 5, padding: 10, width: '100%' }}>
                                        <div onClick={() => window.location.href = `/product-detail/${item.Urun_Id}`}>
                                            <div><img src={item.Medya} alt='' className='rounded' height={140} style={{ maxWidth: 150, zIndex: -1, position: 'relative' }} /></div>
                                            <div className='productName'><small>{item.Urun_Baslik}</small></div>
                                            {item.Renkler !== null ? <div className='d-flex justify-content-center'>
                                                {
                                                    item.Renkler.split(',').map((i, j) => {
                                                        if (j < 5) {
                                                            return (
                                                                <div style={{ width: 20, height: 20, borderRadius: 10, marginLeft: 2, marginRight: 2, border: '1px solid grey', alignItems: 'center', display: 'flex', justifyContent: 'center' }} key={j}>
                                                                    <div style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: i }}></div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div> : <div className='d-flex justify-content-center'><div style={{ width: 20, height: 20, borderRadius: 10, marginLeft: 2, marginRight: 2, border: '1px solid grey', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ width: 16, height: 16, borderRadius: 8, backgroundColor: 'white' }}></div>
                                            </div></div>}
                                            <div><small style={{ fontSize: 11 }}>Ürün Kodu: {item.Urun_TicariYazilimId}</small></div>
                                            <div className='text-secondary'><del>{item.Etiket} {item.DovizTipi}</del></div>
                                            <div><span>{item.Perakende} {item.DovizTipi}</span></div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }) : <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                            <div className='w-100'>
                                <Card className='m-3 text-center p-4 shadow'>
                                    Kriterlere Uygun Ürün Bulunamadı!
                                </Card>
                            </div>
                        </div>
                    }
                </Row>
            </motion.div> : <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <img src={loadImg} alt='' height={30} />
            </div>}


            <Modal isOpen={openModal} toggle={() => setOpenModal(!openModal)} className='modal-dialog-centered'>
                <div className='modal-filter'>
                    <div className='d-flex justify-content-between'>
                        <div><b>Ürün Sırala</b></div>
                        <div onClick={() => setOpenModal(!openModal)}><XCircle size={26} /></div>
                    </div>
                    <div className='my-3 d-flex justify-content-around'>
                        <div className={isCategory ? 'border border-primary px-2 py-1 rounded text-primary shadow' : 'border-0 shadow bg-primary text-white px-2 py-1 rounded'} onClick={() => { setIsCategory(false) }}>Karakter</div>
                        <div className={isCategory ? 'border-0 shadow bg-primary text-white px-2 py-1 rounded' : 'border border-primary px-2 py-1 rounded text-primary shadow'} onClick={() => { setIsCategory(true) }}>Kategori</div>
                    </div>
                    <div className='mt-4'>
                        {!isCategory ? <div>
                            <div><Button onClick={() => { setSorted('0'); getData(0); setOpenModal(!openModal) }}>A'dan Z'ye Sıralı</Button></div>
                            <div className='mt-2'><Button onClick={() => { setSorted('1'); getData(0); setOpenModal(!openModal) }}>Z'den A'ya Sıralı</Button></div>
                            <div className='mt-2'><Button onClick={() => { setSorted('2'); getData(0); setOpenModal(!openModal) }}>Çok Satan</Button></div>
                            <div className='mt-2'><Button onClick={() => { setSorted('3'); getData(0); setOpenModal(!openModal) }}>En Yeniler</Button></div>
                            <div className='mt-2'><Button onClick={() => { setSorted('4'); getData(0); setOpenModal(!openModal) }}>En Düşük Fiyat</Button></div>
                            <div className='mt-2'><Button onClick={() => { setSorted('5'); getData(0); setOpenModal(!openModal) }}>En Yüksek Fiyat</Button></div>
                        </div> : <div style={{ height: 320, overflow: 'auto' }}>
                            {
                                categories.map((item, index) => {
                                    return (
                                        <div className='mt-2' key={index} onClick={() => { getData(item.id); setOpenModal(!openModal); }}>
                                            <Button onClick={() => { }}>{item.baslik}</Button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        }
                    </div>
                </div>
            </Modal>

            <Modal isOpen={errorModal} toggle={() => setErrorModal(!errorModal)} className='modal-dialog-centered'>
                <div>
                    <div className='fw-bold border-bottom pt-3 pb-1 pl-2 pr-2 d-flex justify-content-between'>
                        <div><h6>TECH-IN</h6></div>
                        <div><XCircle color='grey' size={20} style={{ marginTop: -10 }} onClick={() => setErrorModal(!errorModal)} /></div>
                    </div>
                    <div className='p-2'>
                        <div><small>Lütfen internet bağlantınızı kontrol ediniz. Ya da yetkili ile irtibata geçiniz.</small></div>
                        <div className='text-danger text-center mt-3'>((({errorMessage})))</div>
                    </div>
                </div>
            </Modal>
            <BottomBar basket={basketLengthh} />
        </motion.div>
    )
}

export default Product