export const mobilVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        margin: 0,
        width: '100%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        transition: { duration: 0.7 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.7 }
    }
}

export const yandanVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        marginLeft: -100,
        width: '100%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        marginLeft: 0,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 1,
        transition: { duration: 1 }
    }
}

export const contractVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        marginLeft: -100,
        marginTop: 70,
        color: 'rgb(197, 197, 197)',
        width: '100%'
    },
    animate: {
        opacity: 1,
        width: '90%',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 70,
        color: 'black',
        transition: { duration: 1 }
    },
    exit: {
        opacity: 1,
        transition: { duration: 1 }
    }
}

export const vitrinVariant = {
    initial: {
        height: 80,
        borderRadius: 20,
        opacity: 0,
    },
    animate: {
        height: 130,
        borderRadius: 20,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const imgInitVariant = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: { duration: 0.5 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.5 }
    }
}

export const vitrinCategoriesVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0,
    },
    animate: {
        marginTop: 0,
        opacity: 1,
        marginBottom: 70,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const brandCategoriesVariant = {
    initial: {
        marginTop: -1000,
        opacity: 0,
    },
    animate: {
        marginTop: 0,
        opacity: 1,
        marginBottom: 70,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const accountVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0,
    },
    animate: {
        marginTop: 130,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const newBrandsVariant = {
    initial: {
        marginTop: 1000,
        marginLeft: -500,
        opacity: 0,
    },
    animate: {
        marginTop: 150,
        marginLeft: 0,
        marginBottom: 70,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0 }
    }
}

export const productVariant = {
    initial: {
        marginTop: 1000,
        marginLeft: -500,
        opacity: 0,
    },
    animate: {
        marginTop: 120,
        marginLeft: 0,
        marginBottom: 70,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0 }
    }
}

export const searchBrandVariant = {
    initial: {
        marginTop: 1000,
        marginLeft: -500,
        opacity: 0,
    },
    animate: {
        marginTop: 30,
        marginLeft: 0,
        marginBottom: 100,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0 }
    }
}


export const homeSliderVariant = {
    initial: {
        marginLeft: -1000,
        opacity: 0,
    },
    animate: {
        marginLeft: 0,
        opacity: 1,
        marginTop: 124,
        marginBottom: 10,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const payVariant = {
    initial: {
        opacity: 0,
        marginTop: 1000,
        width: '100%'
    },
    animate: {
        width: '90%',
        marginLeft: '5%',
        opacity: 1,
        marginTop: 90,
        marginBottom: 10,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const formVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0,
        width: '20%',
        marginLeft: '40%'
    },
    animate: {
        marginTop: 80,
        marginBottom: 180,
        opacity: 1,
        width: '90%',
        marginLeft: '5%',
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const addressInfoVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0,
        width: '20%',
        marginLeft: '40%'
    },
    animate: {
        marginTop: 40,
        marginBottom: 180,
        opacity: 1,
        width: '90%',
        marginLeft: '5%',
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const addressSaoVariant = {
    initial: {
        marginTop: -100,
        opacity: 0,
        width: '20%',
        marginLeft: '40%'
    },
    animate: {
        marginTop: 100,
        opacity: 1,
        width: '90%',
        marginLeft: '5%',
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const priceInfoVariant = {
    initial: {
        opacity: 0,
        width: '100%',
        marginLeft: -100
    },
    animate: {
        opacity: 1,
        width: '100%',
        marginLeft: 0,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const basketContentVariant = {
    initial: {
        marginTop: 500,
        opacity: 0
    },
    animate: {
        marginTop: 30,
        marginBottom: 100,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const addressVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0,
        width: '20%',
        marginLeft: '40%',
        marginBottom: 320,
    },
    animate: {
        marginTop: 20,
        marginBottom: 0,
        opacity: 1,
        width: '96%',
        marginLeft: '2%',
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const yandanBakiyeVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        marginLeft: -100,
        width: '100%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        marginLeft: 0,
        marginTop: 80,
        display: 'flex',
        justifyContent: 'center',
        transition: { duration: 1 }
    },
    exit: {
        opacity: 1,
        transition: { duration: 1 }
    }
}

export const yandanAboutVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        marginTop: 200,
        width: '90%'
    },
    animate: {
        opacity: 1,
        width: '90%',
        marginLeft: '5%',
        marginTop: 80,
        fontSize: 10,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 1,
        transition: { duration: 1 }
    }
}

export const nullBasketVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        marginTop: 100
    },
    animate: {
        opacity: 1,
        margin: 0,
        transition: { duration: 0.7 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.7 }
    }
}

export const lefMenuMotion = {
    initial: {
        opacity: 0,
        marginLeft: -400
    },
    animate: {
        opacity: 1,
        marginLeft: 0,
        transition: { duration: 0.7 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.7 }
    }
}

export const markaMotion = {
    initial: {
        opacity: 0,
        marginLeft: 1000
    },
    animate: {
        opacity: 1,
        marginLeft: 0,
        marginBottom: 20,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}